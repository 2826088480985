'use strict'

//Based on: http://stackoverflow.com/questions/22537311/angular-ui-router-login-authentication
//Plunker: http://plnkr.co/edit/UkHDqFD8P7tTEqSaCOcc?p=preview

angular
.module('App.UserManagement', [])
// identity is a service that tracks the user's identity. 
// calling identity() returns a promise while it does what you need it to do
// to look up the signed-in user's identity info. for example, it could make an 
// HTTP request to a rest endpoint which returns the user's name, roles, etc.
// after validating an auth token in a cookie. it will only do this identity lookup
// once, when the application first runs. you can force re-request it by calling identity(true)
.factory('identity', [
    '$q',
    '$log',
    '$state',
    'RESTService',
    '$global',
  function($q, $log, $state, RESTService, $global) {
      
      
      var _identity = undefined,
      _authenticated = false,
      _mustChangePassword = false;

    return {
    
     //Returns a boolean that tells you if the user's identity is loaded
      isIdentityResolved: function() {
        //$log.debug("identity.isIdentityResolved()", angular.isDefined(_identity));
          
        //if null or undefined, will return false
        if(_identity) {
            return true;
        }
        else {
            return false;
        }
      },        
        
        
        
      //Returns a boolean that tells you if the user is authenticated
      isAuthenticated: function() {
        //$log.debug("identity.isAuthenticated() ", _authenticated);
        return _authenticated;
      },        
        
      mustChangePassword: function () {
          return _mustChangePassword;
      },
        
        //Expects a role
        //Returns a boolean that is true if the current user has the role that is passed in
      isInRole: function(role) {
        $log.debug("identity.isInRole()", role);
        if (!_authenticated || !_identity.roles) return false;
        $log.debug("_identity.roles :", _identity.roles);
        return _identity.roles.indexOf(role) != -1;
      },        
        
        //Iterates over a users role and repeatedly calls "isInRole" to see if the user has any of the roles provided
        //Expects a list of roles that are authorized to view the page
        //Returns a boolean true if the current users roles match any of the provided roles, false if not.
      isInAnyRole: function(roles) {
        $log.debug("identity.isInAnyRoles() ", roles);
        
        if (!_authenticated || !_identity.roles) return false;

        for (var i = 0; i < roles.length; i++) {
          if (this.isInRole(roles[i])) return true;
        }

        return false;
      },        
        
        

      //Expects: an identity object (user)
    //Returns: VOID (Sets the _authenticated to true if the users identity has been resolved)
      authenticate: function(identity) {
        $log.debug("identity.authentitcate()");
        _identity = identity;
        _authenticated = identity != null;
      },
                
      //used in header.js for displaying logged in user name
      getUserName: function () {
          if (_identity && _identity.name && _identity.name.length > 0) {
              return _identity.name;
          }
      },
        
      //grab the user's roles if they're available, might be useful for displaying
      getRoles: function () {
          if (_identity && _identity.roles && !isNaN(_identity.roles)) {
              return _identity.roles;
          }
      },
        
        //Expects: Boolean force : should we force a new identity lookup
         //Returns: A promise that is resolved once the user is logged in
      identity: function(force) {
        var deferred = $q.defer();
          
        $log.debug("identity.identity()", _identity);
        
        if (force === true) _identity = undefined;

        // check and see if we have retrieved the identity data from the server. if we have, reuse it by immediately resolving
        if (_identity) {
            $log.debug("Loading stored _identity", _identity);
            deferred.resolve(_identity);

          return deferred.promise;
        }

        
        var loginGet = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.LOGIN]));
          
        loginGet.then(function(pl) {
                        
                //is the user logged in?
                if(!Boolean(pl.data[0])){
                    $log.debug("User is not logged in");
                    
                    _identity=null;
                    _authenticated = false;

                    if(Boolean(pl.data[1])) {
                        _mustChangePassword = true;
                    }
                    
                    deferred.resolve();
                    
                } else { //user is logged in, so lets set and return their data
            
                    $log.debug("User is logged in", pl);
                    
                    _authenticated = true;
                    _identity = {
                        name : String(pl.data[3]),
                        roles : [Number(pl.data[2])]
                    };
                    
                    $global.settings.fullName = _identity.name;
                    
                    //TODO: Test this functionality
                    if(Boolean(pl.data[1])){  //user must change password
                        //go to the change password screen
                        //dunno if this is safe
                        //the user can hit any url after this point from changePassword 
                        //because they are logged in after pressing url from emeail
                        $log.debug("User has to change password");
                        _mustChangePassword = true;
                        $state.go('mustChangePassword');
                    }
                    else {
                        $log.debug("User does not have to change password");
                        _mustChangePassword = false;
                    }
                                        
                }
            
                deferred.resolve(_identity);
            
            }, function(err) {
            
                $log.debug("loginGet Fail: ", err);
            
                //The request failed, lets deauth them just in case.
            
                deferred.resolve(_identity);
            });
          

        return deferred.promise;
      },
        
        //Expects: String username, password
        //Returns: HTML alerts for failure or success notification
      login : function(username, password){
          
        $log.debug("Called identity.login()");

        var alert = '';
        
        var params = {
            username: username,
            password: password
        }

        var pathToLogin = RESTService.getControllerPath([$global.RESTControllerNames.LOGIN]);
        
        var loginPost = RESTService.post(pathToLogin, params);

        return loginPost.then(function (pl) {
            if (pl.data) {
                
                $log.debug("Post to Login Endpoint Successful: ", pl);

                var mustChangePassword = Boolean(pl.data[2]);
                
                if (mustChangePassword) {
                    //$log.debug("User Must Change Password");
                    _mustChangePassword = true;
                    $state.go('mustChangePassword');
                }
                else { //successful login
                    //$global.setSysRoleVars(Number(pl.data[0]));
                    //setRoles: function(sysRoleIdent){

                    $global.settings.fullName = String(pl.data[1]);

                    _identity =
                        { 
                            name: String(pl.data[1]),
                            roles: [Number(pl.data[0])] //Role Ident
                        };
                    _authenticated = true;
                    _mustChangePassword = false;


                    $state.go('site.home');
                    
                    return { msg: '<i class="fa fa-exclamation-circle"></i> Logged In Successfully', type: 'success' };
                }
            }
            else {
                return { msg: '<i class="fa fa-exclamation-circle"></i> Invalid Username or Password', type: 'danger' };
            }

        },
        function (errorPl) {
            
            $log.log("Login Unsuccessful");
            
            if (errorPl.status == 401) { //bad username/pass
                return { msg: '<i class="fa fa-exclamation-circle"></i> Invalid Username or Password', type: 'danger' };
            }
            else if (errorPl.status == 403) { //Session locked, tried five random usernames within 5 minutes
                return { msg: '<i class="fa fa-exclamation-circle"></i> Your session is locked and you cannot attempt to login for 5 minutes.', type: 'danger' };
            }
            else { //server failover
                return { msg: '<i class="fa fa-exclamation-circle"></i> Login request failed. Please contact support.', type: 'danger' };
            }

        });

          
      },
      
      //Deprecated
      setRoles: function(sysRoleIdent){
        
        $log.debug("Called identity.setRoles()");
  
        var sysRoleEnum = {
                SYSTEM_ADMIN: 1
            };
          
        switch (sysRoleIdent) {
            case sysRoleEnum.SYSTEM_ADMIN:
                $log.debug("User is Admin");
                return ['User', 'Admin'];
            default:
                $log.debug("User is User");
                return ['User'];
        }
    
      },    
        
            //Logs out current user
            //Return: void
      logout : function(){
        
        var deferred = $q.defer();
          
        $log.debug("identity.logout()");
        
        RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.LOGIN]))
        .then(
            function(){
                $log.debug("Logout Successful");

                //log out
                _identity = null;
                _authenticated = false;
                _mustChangePassword = false;

                deferred.resolve();
            },
            function(){
                $log.debug("Logout Failed");
                deferred.reject();
            }
        );
          
        return deferred.promise;
          
      },
        
        //Expects: newPassword, confirmedNewPassword
        //Returns: HTML alerts/confirmation formated for boostrap explaining error
      changePassword : function(newPassword, confirmNewPassword) {
        var deferred = $q.defer();
        $log.debug("identity.changePassword()");

          
          //TODO: Rewrite this to take current password for user, to authenticate before the change (API Side)
                
        $log.debug("Loaded Controller: " + "ChangePasswordController");

        if (newPassword && newPassword === confirmNewPassword) {

            var changePassPost =    RESTService.post(RESTService.getControllerPath([$global.RESTControllerNames.CHANGEPASS]), { password: newPassword });

            changePassPost.then(function (pl) {
                    if (pl.data) {
                        //let each controller leveraging identity.changePassword decide what to do
                        deferred.resolve({ msg: '<i class="fa fa-exclamation-circle"></i> Your password has been changed.', type: "alert alert-success" });
                    }
                    else {
                        deferred.reject({ msg: '<i class="fa fa-exclamation-circle"></i> Something went wrong. Please be sure your new password does not match your old. If the issue continues please contact support at (###) ###-####.', type: "alert alert-danger" });
                    }
                },
                function (errorPl) {
                    deferred.reject({ msg: '<i class="fa fa-exclamation-circle"></i> We are unable to change your password at this time. Please contact support at (###) ###-####.', type: "alert alert-danger" });
                }
           );
        }
        else if (!angular.isDefined(newPassword)) {
            deferred.reject({ msg: '<i class="fa fa-exclamation-circle"></i> Please make sure your password matches the labeled criteria.', type: "alert alert-danger" });
        }
        else if (newPassword && newPassword !== confirmNewPassword) {
            deferred.reject({ msg: '<i class="fa fa-exclamation-circle"></i> Your password does not match the confirm password.', type: "alert alert-danger" });
        }
          
        return deferred.promise;
          
      }//end changePassword
        
    };//close identity object
  }
])

// authorization service's purpose is to wrap up authorize functionality
// it basically just checks to see if the principal is authenticated and checks the root state 
// to see if there is a state that needs to be authorized. if so, it does a role check.
// this is used by the state resolver to make sure when you refresh, hard navigate, or drop onto a
// route, the app resolves your identity before it does an authorize check. after that,
// authorize is called from $stateChangeStart to make sure the principal is allowed to change to
// the desired state
.factory('authorization', ['$rootScope', '$state', 'identity', '$log',
  function($rootScope, $state, identity, $log) {
    return {
      authorize: function() {
        return identity.identity()
            .then(function() {
            var isAuthenticated = identity.isAuthenticated();
            
            var mustChangePassword = identity.mustChangePassword();
            
            $log.debug("authorization.authorize");
            
            if($rootScope.toState.data) {
                $log.debug("Authorize data.roles ", $rootScope.toState.data.roles);
            } else {
                $log.debug("$rootScope.toState.data doesn't exist");
            }
            
            if ($rootScope.toState.data && $rootScope.toState.data.roles && $rootScope.toState.data.roles.length > 0 && !identity.isInAnyRole($rootScope.toState.data.roles)) {
              if (isAuthenticated) {
                  $log.debug("User is authenticated but does not have permission to view the state: ", $state.current);
                  $state.go('accessdenied'); // user is signed in but not authorized for desired state
              } 
              else {
                  $log.debug("User is not authenticated, sending them to login");
                // user is not authenticated. stow the state they wanted before you
                // send them to the signin state, so you can return them when you're done
                $rootScope.returnToState = $rootScope.toState;
                $rootScope.returnToStateParams = $rootScope.toStateParams;

                // now, send them to the signin state so they can log in
                $state.go('login');
              }
            }
          });
      }
    };
  }
])