angular
.module('App.UsersController')    
.controller('UsersUpdateController',[
    '$scope',
    '$log',
    '$stateParams',
    'RESTService',
    '$global',
function ($scope, $log, $stateParams, RESTService, $global) {

    /*TODO:
    * Add State Directive on Form
    */
    
    $scope.init = function () {
        $log.debug("Loading UsersUpdateController");
        $log.debug("$stateParams: ",$stateParams);
        $scope.alerts = [];
        $scope.user = {};
        $scope.roles = [];
        $scope.getRoles();
        if($stateParams.ident == 0){
            $scope.header = 'Users Add';
        }
        else{
            $scope.getUser();   
        }
    };

    
    $scope.getUser = function(){

        var parameters = [{'key':"Ident", 'value': $stateParams.ident }];
        
        var getUserRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.USER], parameters));
          
        getUserRequest.then(function(pl) {
                $log.debug("User loaded successfully: ", pl.data);
                
                if(pl.data){
                    $scope.user = pl.data;
                    if($scope.user){
                        $scope.header = 'User Edit - ' + $scope.user.firstName + " " + $scope.user.lastName;
                    }
                }
            
            
            }, function(err) {
                $scope.addAlert("danger", 'Error: Unable to retrieve User.');
                $log.log("User failed to load!")
                $log.debug(err);
             
            });

    };
    
    
    $scope.setUser = function(){
        
        $log.debug("Set User: ", $scope.user);
        
        //A magic number dictates that this is a new record
        if($stateParams.ident === 0){            

            var postUserRequest = RESTService.post(RESTService.getControllerPath([$global.RESTControllerNames.USER]), $scope.user);

            postUserRequest.then(function(pl) {
                    $log.debug("User addeded successfully: ", pl.data);
                        
                    $scope.addAlert("success", 'Success: User added.');


                }, function(err) {
                    $log.log("Failed to add user!")
                    $log.debug(err);
                    $scope.addAlert("danger", 'Error: User add failed.');

                });

            //If the magic number is >0 then it's an existing record that we should update.
        }else{

            var putUserRequest = RESTService.put(RESTService.getControllerPath([$global.RESTControllerNames.USER]), $scope.user);

            putUserRequest.then(function(pl) {
                    $log.debug("User updated successfully: ", pl.data);

                    $scope.addAlert("success", "Success: User updated.");


                }, function(err) {
                    $log.log("User failed to load!")
                    $log.debug(err);
                    $scope.addAlert("danger", "Error: User update failed.");

                });
        }

    };


    
    
    $scope.resetPassword = function () {

        if ($scope.user.eMail) {

            var forgotPassPost = RESTService.post(RESTService.getControllerPath([$global.RESTControllerNames.FORGOTPASS]), { email: $scope.user.eMail });

            forgotPassPost.then(function (pl) {
                $scope.alerts.push({ msg: 'An email containing a link to reset your password has been sent to the user.', type: "success" });
                $scope.forgotPasswordEmail = '';
            },
            function (errorPl) {
                $scope.addAlert("danger",'<i class="fa fa-exclamation-circle"></i> We are unable to send an email to the provided address at this time. Please contact your system administrator.');
                $scope.forgotPasswordEmail = '';
            });
        }
        else {
            $scope.addAlert("danger", '<i class="fa fa-exclamation-circle"></i> Please provide an email address.');
        }

    };
    
    
    

    $scope.cancelChanges = function(){
        
        if($scope.user.Ident == 0){
            $scope.user = {
                Ident: $stateParams.ident    
            };
        }
        else{
            $scope.getUser();    
        }
        
    };
    
    
    //setUserLock is used to lock or unlock a user
    //expects: void or boolean - if a boolean is passed the value is set, if void (nothing) is passed, the current value is toggled.
    //returns: void 
    $scope.setUserLock = function(isLocked){
        
        if(angular.isDefined(isLocked)){ //If the developer passes us a boolean, set the lock status to that value
        
            //intentionally blank
            
        } else { //Just toggle the current state and submit it
    
            isLocked = !$scope.user.isLocked
        
        }
            
            
    var lockObj = {
                        "ident" : $stateParams.ident,
                        "lock" : isLocked ? 1 : 0
                    };
    
        $log.debug("setLock: ", lockObj);
        
    var setLockRequest = RESTService.put(RESTService.getControllerPath([$global.RESTControllerNames.USER, $global.RESTControllerNames.LOCK]), lockObj);
        
        setLockRequest.then(function(pl) {
                $log.debug("setLock Successful: ", pl);
                
                //We don't return the current state from the API, only if it's successful - so we have to get the user again.
                //I hope the user didn't make any other changes before deciding to lock/unlock the account they're editing -Clark
                $scope.getUser();
                
            
            }, function(err) {
                $log.log("setLock Failed!")
                $log.debug(err);
            
                $scope.addAlert("danger", "Error: Unable to lock/unlock account.");
            
            });
    
    }
    
    
    //getRoles is used to look up the possible statuses for a user type
    //expects: 
    //returns: void - sets $scope.roles to the payload returned from the API.  It includes key value pairs of status idents and names.
    $scope.getRoles = function() {
        
        
        var getStatusesRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.SYSROLE]));
          
        getStatusesRequest.then(function(pl) {
                $log.debug("Get Roles Successful: ", pl.data);
                
                if(pl.data){
                    $scope.roles = pl.data;
                }
                
            
            }, function(err) {
                $log.log("Get Roles Failed!")
                $log.debug(err);
            
                $scope.addAlert("danger", "Error: Unable to retrieve roles.");
            
            });
    };

    
    
    $scope.addAlert = function(type, message) {
          $scope.alerts.push({msg: message, type:type});
    };

    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    
    //Initialize
    $scope.init();
}]);

