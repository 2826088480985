'use strict'

angular
.module('App.ForgotPasswordController', [])
.controller('ForgotPasswordController',
['$scope',
    '$log',
    '$global',
    '$location',
    'RESTService',
function ($scope, $log, $global, $location, RESTService) {

    $log.debug("Controller: " + "ForgotPasswordController");

    $scope.username = '';
    $scope.password = '';
    $scope.invalid = false;

    $scope.forgotPassword = function () {

        $scope.alerts = [];

        if ($scope.forgotPasswordEmail) {

            var forgotPassPost = RESTService.post($global.RESTControllerNames.FORGOTPASS, { email: $scope.forgotPasswordEmail });

            forgotPassPost.then(function (pl) {
                $scope.alerts.push({ msg: 'An email containing a link to reset your password has been sent to the provided address.', type: "success" });
                $scope.forgotPasswordEmail = '';
                progressLoader.end();
            },
            function (errorPl) {
                $scope.alerts.push({ msg: '<i class="fa fa-exclamation-circle"></i> We are unable to send an email to the provided address at this time. Please contact your system administrator.', type: "danger" });
                $scope.forgotPasswordEmail = '';
                progressLoader.end();
            });
        }
        else {
            $scope.alerts.push({ msg: '<i class="fa fa-exclamation-circle"></i> Please provide an email address.', type: "danger" });
        }

    };

    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };

}]);