'use strict'

angular
.module('App.LoginController', [])
.controller('LoginController',
[   '$scope',
    '$global',
    '$rootScope',
    '$log',
    '$state',
    'RESTService',
    'identity',
function ($scope, $global, $rootScope, $log, $state, RESTService, identity) {

    $log.debug("Controller: " + "LoginController");

    $scope.alerts = [];
    $scope.username = '';
    $scope.password = '';
    $scope.invalid = false;
    
    //Delegates to identity.login()
    //Sets $global.settings.isLoggedIn to true on success
    //Returns an alert object { msg : "alert message", type : "danger" }
    $scope.login = function(){
        
        identity.login($scope.username, $scope.password)
            .then(  
                    //success
                    function(value){
                        $scope.alerts.push(value);
                    },
                    //failure
                    function(value){
                        $scope.alerts.push(value);
                    }
                  );
    }
    
    //Sends a Forgot Password Email
    //returns: Adds an Alert to the Alert Box
    function sendUserEmail() {

        var forgotPassPost = RESTService.post($global.RESTControllerNames.FORGOTPASS, { email: $scope.currentRecord.eMail });

        forgotPassPost.then(function (pl) {
            $scope.alerts.push({ msg: 'An email containing a link to reset your password has been sent to the provided address.', type: "success" });
        },
        function (errorPl) {
            $scope.alerts.push({ msg: '<i class="fa fa-exclamation-circle"></i> We are unable to send an email to the provided address at this time. Please contact your system administrator.', type: "danger" });
        });
    };

    //Closes an alert
    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };

}]);
