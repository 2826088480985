'use strict'

angular
.module('App.MaintenanceController', [])
.controller('MaintenanceController',[
    '$scope',
    '$log',
    '$global', 
    'RESTService', 
function ($scope, $log, $global, RESTService) {
    $scope.alerts = [];
    $scope.maintenanceResults = null;
    $scope.maintenanceTypes = [
        { Ident : 1, Type: "Division" }, 
        { Ident : 2, Type: "Status" }, 
        { Ident : 3, Type: "Type" },
        { Ident : 4, Type: "County" }
    ];
    $scope.type = $scope.maintenanceTypes[0];
    $scope.dataLoaded = false;
    
    
    $scope.buildMaintenanceParameters = function(id){
        var parameters = [{ 'key': "tableName", 'value' : "" }];
        
        switch(id){
            case 1:
                parameters[0].value = "Division";
                break;
            case 2:
                parameters[0].value = "Status";
                break;
            case 3:
                parameters[0].value = "Type";
                break;
            case 4: 
                parameters[0].value = "County";
                break;
        }
        
        return parameters;
    };
    
    
    $scope.getMaintenanceTypeResults = function(){
        
        $scope.dataLoaded = false;

        if($scope.type){
            
            $scope.header = 'Maintenance - ' + $scope.type.Type;
            
            var parameters = $scope.buildMaintenanceParameters($scope.type.Ident);
            $scope.maintenanceResults = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.DOMAIN], parameters));
            
            $scope.maintenanceResults.then(function(results){
                $scope.maintenanceResults = results.data;

                $scope.dataLoaded = true;
            }, 
            function(error){
               $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve maintenance records.' });

               $scope.dataLoaded = true;
            });
            
        }
    };
    
    $scope.addMaintenanceRecord = function(){
        var parameters = $scope.buildMaintenanceParameters($scope.type.Ident);
        
        var addSuccess = RESTService.post(
            RESTService.getControllerPath([$global.RESTControllerNames.DOMAIN], parameters),
            $scope.maintenance
        );

        addSuccess.then(function(response){
            $scope.alerts.push({ type: 'success', msg: 'Success: Record added.' });
            $scope.maintenanceResults = response.data;
            $scope.maintenance = {};
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Add failed.' });            
        });        
    };
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    $scope.getMaintenanceTypeResults();
    
}]);
