angular
.module('App.UsersController')
.controller('UsersSearchController',[
    '$scope',
    '$log',
    '$filter',
    'RESTService',
    '$global',
    function ($scope, $log, $filter, RESTService, $global) {
        
    /* TODO:
    * Fix Pagination links at bottom of the screen
    */
        
    $scope.init = function () {
        $log.debug("Loading UsersSearchController");
        $scope.users = [];
        $scope.getUsers();
        $scope.alerts = [] ;
    };
    

    
    $scope.getUsers = function() {
        
        var UsersRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.USER]));
          
        UsersRequest.then(function(pl) {
                $log.debug("Users loaded successfully: ", pl.data);
                
                if(pl.data){
                    $scope.users = pl.data;
                }
                
            
            }, function(err) {
                $log.log("Users failed to load!")
                $log.debug(err);
            
                $scope.addAlert("danger", 'Error: Unable to retrieve users.');
            
            });
    
    };

        
        
        
    $scope.deleteUser = function(id) {
        
        var parameters = []
        parameters.push({ 'key': "ident", 'value' : id });
        
        var deleteUserRequest = RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.USER], parameters));
          
        deleteUserRequest.then(
            function(pl) {
                $log.debug("Delete User Successful: ", id , pl.data);
                $scope.Users = pl.data;
                $scope.getUsers();
                $scope.addAlert("success", "Success: Attorney removed.");
            }, function(err) {
                $log.log("Delete User failed!")
                $log.debug(err);
                $scope.addAlert("danger", "Error: Attorney removal failed.");
            
            });
    
    };
        
    
    $scope.addAlert = function(type, message) {
          $scope.alerts.push({msg: message, type:type});
    };

    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };

    $scope.isActive = function(status) {
        if(status){
            return status;
        } else {
            return "Inactive";
        }
    }
            
    //Initialize
    $scope.init();
}]);

