angular
.module('App.MasterListController')    
.controller('MasterListUpdateController',[
    '$scope',
    '$log',
    '$stateParams',
    'RESTService',
    '$state',
    '$global',
    '$states',
    '$alerts',
    'identity',
function ($scope, $log, $stateParams, RESTService, $state, $global, $states, $alerts, identity) {

    /*TODO:
    * Add State Directive on Form
    * Make sure BulletinFlag is updated to Boolean
    * Additional Validation
    */
    
    $scope.init = function () {
        $log.debug("Loading MasterListUpdateController");
        $log.debug("$stateParams: ",$stateParams);
        $scope.alerts = [];


        $scope.readOnly = false;

        // 2= readonly
        if (identity.getRoles() == 2){

            $scope.readOnly = true;

        };

        //Load Counties for Dropdown
        $scope.counties = []; //$global.getProperty("County");
        $global.getProperty("County").then(function(results){
            $scope.counties = results.data;
            $log.debug("$scope.counties: ", $scope.counties);
        }, 
        function(error){
            $log.error("Failed to get counties");
           $alerts.addAlert("danger","Error: Unable to retrieve counties.");    
        });
                        
        //Load Member Types
        $scope.memberTypes = [];
        $global.getProperty("Type").then(function(results){
            $scope.memberTypes = results.data;
            //Adds an empty item at the front of the list, so that you can "unselect" in the dropdown
            $scope.memberTypes.unshift({'Ident':'','FullName':''}); 
            $log.debug("$scope.memberTypes: ", $scope.memberTypes);
        }, 
        function(error){
            $log.error("Failed to get memberTypes");
           $alerts.addAlert("danger","Error: Unable to retrieve member types.");    
        });

        
        //Load Divisions
        $scope.divisions = [];
        $global.getProperty("Division").then(function(results){
            $scope.divisions = results.data;
            //Adds an empty item at the front of the list, so that you can "unselect" in the dropdown
            $scope.divisions.unshift({'Ident':'','FullName':''}); 
            $log.debug("Load divisions: ", $scope.divisions);
        }, 
        function(error){
            $log.error("Failed to get divisions");
           $alerts.addAlert("danger","Error: Unable to retrieve divisions.");    
        });
        
        //Load Statuses
        $scope.memberStatuses = [];
        $global.getProperty("Status").then(function(results){
            $scope.memberStatuses = results.data;
            //Adds an empty item at the front of the list, so that you can "unselect" in the dropdown
            $scope.memberStatuses.unshift({'Ident':'','FullName':''}); 
            $log.debug("Load divisions: ", $scope.memberStatuses);
        }, 
        function(error){
            $log.error("Failed to get member statuses");
           $alerts.addAlert("danger","Error: Unable to retrieve member statuses.");    
        });

        $scope.states = $states.states;
      
        //If we're adding a new attorney, we need to populate their ident
        $scope.attorney = {
            Ident: $stateParams.ident
        };
        
        //Adjust the heading and our actions based on the URL
        if($scope.attorney.Ident == 0){
            $scope.header = 'Master List Add';
        }
        else{
            $scope.getAttorney();   
        }
        
        //Set Up our DatePicker Options        
        $scope.dateOptions = {
            formatYear: 'yy' //use a two digit year
        };        
        
    };

    
    $scope.getAttorney = function(){

        $scope.dataLoaded = false;

        var getAttorneyRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST]), $scope.attorney.Ident);
          
        getAttorneyRequest.then(function(pl) {
                $log.debug("Attorney loaded successfully: ", pl.data);
                
                if(pl.data){


                    $scope.attorney = pl.data;

                    if (pl.data.AdmittedDate == '1900-01-01T00:00:00'){

                        $scope.attorney.AdmittedDate = null;


                    } else {

                        $scope.attorney.AdmittedDate = new Date(pl.data.AdmittedDate.substring(0,4), (parseInt(pl.data.AdmittedDate.substring(5,7)) - 1), pl.data.AdmittedDate.substring(8,10));

                    };

                    // convert admitted date to date object
                    $scope.header = 'Master List - ' + $scope.attorney.FullName;
                }

                // dont display 0 on screen
                if ($scope.attorney.MemberNumber === 0){

                    $scope.attorney.MemberNumber = null;

                };

                // Turn off loading icon
                $scope.dataLoaded = true;

            }, function(err) {
                $scope.addAlert("danger", 'Error: Unable to retrieve Attorney.');
                $log.log("Attorney failed to load!")
                $log.debug(err);

                // Turn off loading icon
                $scope.dataLoaded = true;
            });
        
        $scope.admittedIsOpen = false;

    };
    
    
    //open() displays a date picker
    //expects: $event (The mouse click), picker (Which event picker you want to open)
    //returns: void - Opens the ui-bootstrap datepicker             
    $scope.open = function($event) {
        $log.debug("Open Clicked");
        $event.preventDefault();
        $event.stopPropagation();

        $scope.admittedIsOpen = true;
    };

    
    
    $scope.setAttorney = function(){
        
        $log.debug("Set Attorney: ", $scope.attorney);
        
        //A magic number dictates that this is a new record
        if($stateParams.ident === 0){            

            var postAttorneyRequest = RESTService.post(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST]), $scope.attorney);

            postAttorneyRequest.then(function(pl) {
                    $log.debug("Attorney addeded successfully: ", pl.data);
                    $state.go('site.masterlist.update', {ident: pl.data}); //, {notify: false}
                    $scope.addAlert("success", 'Success: Attorney added.');
                    


                }, function(err) {
                    $log.log("Failed to add attorney!")
                    $log.debug(err);
                    $scope.addAlert("danger", 'Error: Attorney add failed.');

                });

            //If the magic number is >0 then it's an existing record that we should update.
        }else{

            var putAttorneyRequest = RESTService.put(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST]), $scope.attorney.Ident, $scope.attorney);

            putAttorneyRequest.then(function(pl) {
                    $log.debug("Attorney updated successfully: ", pl.data);

                    $alerts.addAlert("success", "Success: Attorney updated.");


                }, function(err) {
                    $log.log("Attorney failed to load!")
                    $log.debug(err);
                    $alerts.addAlert("danger", "Error: Attorney update failed.");

                });
        }

    };

    $scope.cancelChanges = function(){
        
        if($scope.attorney.Ident == 0){
            $scope.attorney = {
                Ident: $stateParams.ident    
            };
        }
        else{
            $scope.getAttorney();    
        }
        
    };
    

    //Alerts Boilerplate
    $scope.addAlert = function(type, message) {
          $alerts.addAlert(type, message);
    };

    $scope.closeAlert = function(index) {
        $alerts.closeAlert(index);
    };
    
    //ADD $watch to populate $scope.alerts
    $scope.$watch($alerts.alerts, function() {
        $scope.alerts = $alerts.alerts;
    });
    
    //Initialize
    $scope.init();
}]);

