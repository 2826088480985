'use strict'

angular
.module('App.CommitteeUpdateController', [])
.controller('CommitteeUpdateController',[
    '$scope',
    '$log',
    '$stateParams',
    'RESTService',
    '$global',
    '$state', 
function ($scope, $log, $stateParams, RESTService, $global, $state) {
    
    $scope.committee = {
        Ident : $stateParams.ident
    };
    $scope.alerts = [];
    $scope.dataLoaded = false;
    
    $scope.getCommitteeByIdent = function(){
        
        $scope.committee = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE]), 
            $stateParams.ident
        );
        
        $scope.committee.then(function(results){
            $scope.committee = results.data;
            $scope.header = "Committee Edit - " + $scope.committee.CommitteeName;

            // Turn off loading icon
            $scope.dataLoaded = true;
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve committee.' });

            // Turn off loading icon
            $scope.dataLoaded = true;
        });
    };
    
    $scope.saveCommittee = function(){
        
        //If we are editing a committee
        if($scope.committee.Ident > 0){
            var saved = RESTService.put(
                RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE]),
                $scope.committee
            );

            saved.then(function(response){
                $scope.alerts.push({ type: 'success', msg: 'Success: Committee updated.' });
            }, 
            function(error){
                $scope.alerts.push({ type: 'danger', msg: 'Error: Committee update failed.' });        
            });
        }
        else{ //We're adding a new record 
            var added = RESTService.post(
                RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE]),
                $scope.committee
            );
            
            added.then(function(response){
                $scope.committee.Ident = response.data;
                $state.go('site.committee.update', {ident : $scope.committee.Ident}, {notify: false});
                $scope.header = "Committee Edit - " + $scope.committee.CommitteeName; 
                $scope.alerts.push({ type: 'success', msg: 'Success: Committee added.' });
            }, 
            function(error){
                $scope.alerts.push({ type: 'danger', msg: 'Error: Committee add failed.' });            
            });
            
        }
                   
    };
    
    $scope.viewCommitteeMembers = function(){
        $state.go('site.committee.committeemembers', {ident:$scope.committee.Ident});
    }
    
    $scope.cancelChanges = function(){
        
        if($stateParams.ident > 0){
            $scope.getCommitteeByIdent();    
        }
        else{
            $scope.committee = {};   
        }
            
    };
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    if($stateParams.ident > 0){
        $scope.getCommitteeByIdent();    
    }
    else{
        $scope.header = "Committee Add";
    }
    
}]);

