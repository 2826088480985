'use strict'

angular
.module('App.MustChangePasswordController', [])
.controller('MustChangePasswordController',
[   '$scope',
    '$log',
    '$state',
    'identity',
function ($scope, $log, $state, identity) {

 
    if(!identity.mustChangePassword()) {
        $state.go('accessdenied');
    }
    
    $scope.init = function(){
        $log.debug("Loaded Controller: " + "MustChangePasswordController");
        $scope.alerts = [];
        $scope.regex = new RegExp(/(?=.*\d)(?=.*[a-zA-Z]).{8,50}/); //regex for password ng-pattern
        $scope.newPassword = '';
        $scope.confirmNewPassword = '';
    }
    
    //checks to see if the minimum complexity requirements are met and both passwords match.
    $scope.isInvalid = function(){
        return !(($scope.newPassword == $scope.confirmNewPassword) && $scope.regex.test($scope.newPassword) && ($scope.newPassword.length>0));
    }
    
  //delegates to identity.changePassword()
    $scope.changePassword = function () {
        
        $log.debug("changePassword()");
        
        var promise = identity.changePassword($scope.newPassword, $scope.confirmNewPassword);
        
        promise.then(function (result) {
            $scope.newPassword = "";
            $scope.confirmNewPassword = "";
            $scope.alerts.push(result);
            $state.go('site.home');
        },
        function (errorResult) {
            $scope.alerts.push(errorResult);
        });
        
    };

    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };

    $scope.init();
    
}]);