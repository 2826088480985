'use strict'

angular
.module('App.PledgeUpdateController', [])

.controller('PledgeUpdateController',[
    '$scope',
    '$log',
    'RESTService',
    '$global',
    '$stateParams',
    '$state',
    'identity',
function ($scope, $log, RESTService, $global, $stateParams, $state, identity) {

    $scope.pledge = {
        Ident : $stateParams.ident, 
        MasterListIdent : $stateParams.masterListIdent
    };
    $scope.alerts = [];
    $scope.divisions = {};
    $scope.years = [];
    $scope.dateOptions = {
        formatYear: 'yy'
    };
    $scope.dataLoaded;

    
    $scope.readOnly = false;

    // 2= readonly
    if (identity.getRoles() == 2){

        $scope.readOnly = true;

    };
    
    $scope.getDivisions = function(){
        
        var parameters = [{'key':"tableName", 'value': "Division" }];
        
        $scope.divisions = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.DOMAIN], parameters));
        $scope.divisions.then(function(results){
            $scope.divisions = results.data;
            $log.debug("Get Divisions: ", results.data);
        }, 
        function(error){
           $scope.alerts.push({ type: 'Danger', msg: 'Error: Unable to retrieve divisions.' });    
        });
    };
    
    $scope.getPledge = function(){
        
        $scope.dataLoaded = false;

        var resultSet = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.PLEDGE]), 
            $stateParams.ident
        );
        
        resultSet.then(function(results){
            $scope.pledge = results.data;
            $scope.header = "Pledge Edit - " + results.data.FullName;
            $log.debug("Get Pledge: ", results.data);

            // Turn off loading icon
            $scope.dataLoaded = true;
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve pledge.' });

            // Turn off loading icon
            $scope.dataLoaded = true;
        });
    };
    
    $scope.getMasterList = function(){
        var resultSet = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST]), 
            $stateParams.masterListIdent
        );
        
        resultSet.then(function(results){
            $scope.pledge.FullName = results.data.FullName;
            $scope.header = "Pledge Add - " + $scope.pledge.FullName;
            $log.debug("Get Master List: ", results.data);
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve member.' });
        });
    };
    
    $scope.savePledge = function(){
        
        $scope.pledge.MasterListIdent = $stateParams.masterListIdent;
        
        if($stateParams.ident > 0){
            var updated = RESTService.put(
                RESTService.getControllerPath([$global.RESTControllerNames.PLEDGE]),
                $scope.pledge
            );
            
            updated.then(function(response){
                $scope.alerts.push({ type: 'success', msg: 'Success: Pledge updated.' });
            }, 
            function(error){
                $scope.alerts.push({ type: 'danger', msg: 'Error: Pledge update failed.' });            
            });
        }
        else{
            $scope.pledge.Year = $scope.pledge.Year.Year;
            
            var added = RESTService.post(
                RESTService.getControllerPath([$global.RESTControllerNames.PLEDGE]),
                $scope.pledge
            );

            added.then(function(response){
                $scope.pledge.Ident = response.data;
                $log.debug("Save Pledge: ", response.data);
                $state.go('site.pledge.update', {ident:$scope.pledge.Ident}, {notify: false});
                $scope.alerts.push({ type: 'success', msg: 'Success: Pledge added.' });
                $scope.header = $scope.header = "Pledge Edit - " + pledge.FullName;
            }, 
            function(error){
                $scope.alerts.push({ type: 'danger', msg: 'Error: Pledge add failed.' });            
            });    
        } 
    };
    
    $scope.cancelChanges = function(){
        if($stateParams.ident > 0){
            $scope.getPledge();
        }
        else{
            $scope.pledge = null;
            $scope.pledge = {
                Ident: $stateParams.ident
            };
        }   
    };
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };

    
    $scope.loadYears = function(){
    
        var parameters = [
            {'key':"masterListIdent", 'value': $stateParams.masterListIdent } 
        ];
        
        var resultSet = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.PLEDGE], parameters)
        );
        
        resultSet.then(function(results){
            $scope.years = results.data;
            $log.debug("Load Years: ", results.data);
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve years.' });
        });
    };
    
    $scope.open = function($event,opened) {
        $event.preventDefault();
        $event.stopPropagation();
        
        $scope.opened1 = false;
        $scope.opened2 = false;
        $scope.opened3 = false;
        $scope.opened4 = false;
        $scope.opened5 = false;
        
        $scope[opened] = true;
    };
    
    $scope.getDivisions();
    
    //We are editing a record
    if($stateParams.ident > 0){
        $scope.getPledge();
    }
    else{ //Adding a new record
        $scope.loadYears();
        $scope.getMasterList();
    }
    
}]);

