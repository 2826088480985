'use strict'

angular
.module('App.DashboardController', [])
.controller('DashboardController',[
    '$scope',
    '$log',
function ($scope, $log) {

    $scope.init = function () {
        $log.debug("Loading DashboardController");
    };

    //Initialize
    $scope.init();
}]);
