'use strict'

angular
.module('App.MasterListCommitteesController', [])
.controller('MasterListCommitteesController',[
    '$scope',
    '$stateParams', 
    'RESTService',
    '$global',
    'identity',
function ($scope, $stateParams, RESTService, $global, identity) {

    $scope.init = function(){
        $scope.header = 'Committees'
        $scope.getMasterList();
        $scope.alerts = [];
        $scope.currentRecord = {};
        $scope.masterListCommittees = {};
        $scope.availableCommittees = {};
        $scope.getMasterListCommittees();
        $scope.getAvailableCommittees();
        $scope.dataLoaded = false;


        $scope.readOnly = false;

        // 2= readonly
        if (identity.getRoles() == 2){

            $scope.readOnly = true;

        };
    };

    
    $scope.getMasterList = function(){
        
        var searchResults = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST]), 
            $stateParams.masterListIdent
        );
        
        searchResults.then(function(results){
            $scope.currentRecord.FullName = results.data.FullName;
            $scope.currentRecord.Ident = $stateParams.masterListIdent;
            $scope.header = $scope.currentRecord.FullName + ' - Committees';

            
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve Master List.' });

            
        });
        
    };
    
    
    $scope.getMasterListCommittees = function(){
        
        var parameters = [{ 'key': "masterListIdent", 'value' : $stateParams.masterListIdent }];
        
        var searchResults = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEEMEMBER], parameters)
        );
        
        searchResults.then(function(results){ 
            $scope.masterListCommittees = results.data;

            // Turn of loading icon
            $scope.dataLoaded = true;
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve Master List Committees.' });

            // Turn of loading icon
            $scope.dataLoaded = true;
        });
        
    };
    
    
    $scope.getAvailableCommittees = function(){
        
        var parameters = [{ 'key': "masterListIdent", 'value' : $stateParams.masterListIdent }];
        
        var searchResults = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE], parameters)
        );
        
        searchResults.then(function(results){ 
            $scope.availableCommittees = results.data;
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve Committees.' });
        });
        
    };
    
    
    $scope.addMasterListCommittee = function(){
        if($scope.committee){
        
            var parameters = [{ 'key': "masterListIdent", 'value' : $stateParams.masterListIdent }];
            
            var masterListCommittee ={
                MasterListIdent: $stateParams.masterListIdent, 
                CommitteeIdent: $scope.committee.Ident
            };

            var addSuccess = RESTService.post(
                RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEEMEMBER], parameters),
                masterListCommittee
            );

            addSuccess.then(function(response){
                $scope.alerts.push({ type: 'success', msg: 'Success: Master List Committee added.' });
                $scope.masterListCommittees = response.data;
                $scope.getAvailableCommittees();
                $scope.committee = null;
            }, 
            function(error){
                $scope.alerts.push({ type: 'danger', msg: 'Error: Master List Committee add failed.' });            
            });
            
        }
    };
    
    
    $scope.removeMasterListCommittee = function(ident){
        var parameters = [
            { 'key': "masterListIdent", 'value' : $stateParams.masterListIdent },
            { 'key': "ident", 'value' : ident }
        ];
        
        var removal = RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEEMEMBER], parameters));
        removal.then(function(result){
            $scope.masterListCommittees = result.data;      
            $scope.alerts.push({ type: 'success', msg: 'Success: Master List Committee removed.' });
            $scope.getAvailableCommittees();
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Master List Committee removal failed.' });
        });
    };
    
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    
    $scope.init();
}]);

