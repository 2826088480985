'use strict'

angular
.module('App.DuesController', [])

.controller('DuesSearchController',[
    '$scope',
    '$log',
    '$filter',
    '$stateParams',
    'RESTService',
    '$global',
    'identity',
    function ($scope, $log, $filter, $stateParams, RESTService, $global, identity) {
        
        
    //URL should look like: http://eriebar:4444/#/dues/1374/page/1
        
    //Set up our controller
    $scope.init = function () {
        $log.debug("Loading DuesSearchController");
        $scope.dues = [];
        $scope.alerts = [];
        $scope.editPaymentIdent = -1;
        $scope.perPage = 1000; //Records per page to show
        $scope.attorneyIdent = $stateParams.ident;
        $scope.attorney = {};
        $scope.getAttorney($scope.attorneyIdent);
        $scope.getDues($scope.attorneyIdent, 100, 1);
        $scope.addRow = false;
        $scope.add = {};
        $scope.dateOptions = {
            formatYear: 'yy'
        };
        $scope.opened = [];
        $scope.dataLoaded = false;

        $scope.readOnly = false;

        // 2= readonly
        if (identity.getRoles() == 2){

            $scope.readOnly = true;

        };
    }; // init
    

        
        
    //setEditable is used to make a row editable in the dues history table
    //Expects: ident is which row
    //Returns: Uses editPaymentIdent to let the digest cycle know which row is editable in the view model
    $scope.setEditable = function(ident) {
        $scope.editPaymentIdent = ident;
    }
        
    
    
    
    //getAttorney is used to load information about the Attorney who's dues history we are viewing
    //Expects: ident is the MasterListIdent for the attorney who's dues history we're viewing - it's available in the $StateParams
    //Returns: sets $scope.attorney equal to the returned payload data from the API
    $scope.getAttorney = function(ident) {
//    /api/MasterList?Ident=1
        
        var attorneyRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST]), ident);
          
        attorneyRequest.then(function(pl) {
            $log.debug("Attorney loaded successfully: ", pl.data);
                
            if(pl.data){
                    $scope.attorney = pl.data;
            }
                
            
            }, function(err) {
                $log.log("Attorney failed to load!")
                $log.debug(err);
            
                $scope.addAlert("danger", "There was a problem loading attorney information.");
            
            });

    }
        
    
    
    
    //getDues is used to retrieve the dues history for an attorney
    //expects: ident is the MasterListIdent of the attorney, perPage is how many rows we should return perPage, currentPage is what page we're viewing
    //returns: sets the $scope.dues object to the results of the payload from the API
    $scope.getDues = function(ident, results, page) {
        
        var parameters = [{'key':"MasterListIdent", 'value':ident}, {'key':"perPage", 'value': $scope.perPage }, { 'key': "currentPage",'value': $stateParams.currentPage }];
        
        var duesRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.DUES], parameters));
          
        duesRequest.then(function(pl) {
                $log.debug("Dues loaded successfully: ", pl.data);
                $scope.dataLoaded = true;
                
                if(pl.data){
                    $scope.dues = pl.data.items;
                }
                
            
            }, function(err) {
                $log.log("Dues failed to load!")

                // Disable loading icon even though there is no data to display
                $scope.dataLoaded = true;

                $log.debug(err);
            
                $scope.addAlert("danger", "There was a problem loading the users dues history.");
            
            });
    };
        
    
    //addPayment is used to add a new payment to the attorney's dues history
    //expects: a payment object
    //returns: The API returns a new dues history - $scope.dues is set to the payload returned by the API
    $scope.addPayment = function(payment) {
                
        var parameters = [{'key':"perPage", 'value': $scope.perPage }, { 'key': "currentPage",'value': $stateParams.currentPage }];
        
        var addPaymentRequest = RESTService.post(RESTService.getControllerPath([$global.RESTControllerNames.DUES],parameters), payment);
            
        //Add the Attorney's Ident to the payment
        payment.MasterListIdent = $scope.attorneyIdent;
        
        $log.debug("Posting Payment: ", payment);
        
        addPaymentRequest.then(function(pl) {
                $log.debug("Dues paid successfully: ", pl.data);                
                $scope.dues = pl.data.items;
                $scope.addRow = false;
                $scope.add={};
                $scope.addAlert("success", "Success: Dues added.");
            
            }, function(err) {
                $log.log("Dues payment failed!")
                $log.debug(err);
            
                $scope.addAlert("danger", "Error: Dues add failed.");
            
            });
    
    };
        
        
        
    //updatePayment is used to update a specific payment in the attorney's dues history
    //expects: a payment object
    //returns: void - changes $scope.setEditable to -1 so the row being saved is no longer editable
    $scope.updatePayment = function(payment) {
                
        var updatePaymentRequest = RESTService.put(RESTService.getControllerPath([$global.RESTControllerNames.DUES]), payment);
       
        //Add the Attorney's Ident to the payment
        payment.MasterListIdent = $scope.attorneyIdent;
        
        $log.debug("Posting Payment Update: ", payment);
        
        updatePaymentRequest.then(function(pl) {
                $log.debug("Payment updated successfully: ", pl.data);
            
                //$scope.dues = pl.data;
                //Set our editable row to an out-of-bounds index so that everything changes back to the span instead of the input.
                $scope.setEditable(-1);
                $scope.addAlert("success", "Success: Dues updated.");
            
            }, function(err) {
                $log.log("Payment update failed!")
                $log.debug(err);
            
                $scope.addAlert("danger", "Error: Dues update failed.");
            
            });
    
    };

        
        
    
    //deletePayment is used to remove a specific payment in the attorney's dues history
    //expects: a dues ident
    //returns: void - splices the deleted ident out of $scope.dues
    $scope.deletePayment = function(ident) {
         
        var parameters = [{'key':"masterListIdent", 'value':ident}, {'key':"Ident", 'value':ident}, {'key':"perPage", 'value': $scope.perPage }, { 'key': "currentPage",'value': $stateParams.currentPage}];
        var deletePaymentRequest = RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.DUES], parameters));
          
        deletePaymentRequest.then(
            function(pl) {
                $log.debug("Delete Payment Successful: ", ident , pl.data);
                
                //Find the correct index and remove the object from 
                angular.forEach($scope.dues, function(value, key){
                
                    if(ident==value.Ident){
                        $scope.dues.splice(key, 1);
                    }
                
                });
                
                $scope.addAlert("success", "Success: Dues removed.");
                
            }, function(err) {
                $log.log("Delete Payment failed!")
                $log.debug(err);
                $scope.addAlert("danger", "Error: Dues removal failed.");
            
            });
    
    };
        
        
        
    //addAlert adds a new alert object to the $scope.alerts array
    //expects: type is a bootstrap class for alerts (eg. success, warning, danger, info), message is the message you want displayed in the alert box
    //returns: void - adds a new alert object to the $scope.alerts array
    $scope.addAlert = function(type, message) {
          $scope.alerts.push({msg: message, type:type});
    };

    //closeAlert closes the alert box in the view model
    //expects: index is the position in the array of the box you want to close
    //returns: void - removes the item at index out of the $scope.alerts array
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
        
    $scope.open = function($event,index) {
        $event.preventDefault();
        $event.stopPropagation();
        
        $scope.opened[-2] = false;
        $scope.opened[-1] = false;
        //Close all opened datepickers before we open another one
        angular.forEach($scope.opened, function(value, key){
            $scope.opened[key] = false;
        });
        
        $scope.opened[index] = true;
    };    

            
    //Initialize
    $scope.init();
}]);


