angular
.module('App.MasterListController')
.controller('MasterListSearchController',[
    '$scope',
    '$log',
    '$filter',
    'RESTService',
    '$global',
    '$states',
    '$alerts',
    'identity',
    function ($scope, $log, $filter, RESTService, $global, $states, $alerts, identity) {
        
    /* TODO:
    * Search Functionality
    * Eliminate Garbage Rows from Data
    * Fix Pagination links at bottom of the screen
    */

    $scope.init = function () {
        $log.debug("Loading MasterListSearchController");
        $scope.states = $states.states;

        $scope.readOnly = false;

        // 2= readonly
        if (identity.getRoles() == 2){

            $scope.readOnly = true;

        };

        $scope.dataLoaded = false;
        $scope.perPage = 10;
        $scope.currentPage = 1;
        
        //Load Counties for Dropdown
        $scope.counties = []; //$global.getProperty("County");
        $global.getProperty("County").then(function(results){
            $scope.counties = results.data;
            $log.debug("$scope.counties: ", $scope.counties);
        }, 
        function(error){
            $log.error("Failed to get counties");
           $alerts.addAlert("danger","Error: Unable to retrieve counties.");      
        });
        
        $scope.masterList = [];
        $scope.getMasterList();
        $scope.alerts = [] ;
        $scope.isCollapsed = true; //"Show additional search options"
        $scope.query = { 
            FirmCountyIdent: 0,
            sex: '',
            MasterListTypeIdent: 0,
            masterListStatus: 0,
            masterListDivisionIdent: 0,
            bulletinFlag: false,
            mailingListOptOut: false,
            showDeletedRecords: false
        };
        
        //Load Member Types
        $scope.memberTypes = [];
        $global.getProperty("Type").then(function(results){
            $scope.memberTypes = results.data;
            $log.debug("$scope.memberTypes: ", $scope.memberTypes);
        }, 
        function(error){
            $log.error("Failed to get memberTypes");
           $alerts.addAlert("danger","Error: Unable to retrieve member types.");    
        });

        //Load Divisions
        $scope.divisions = [];
        $global.getProperty("Division").then(function(results){
            $scope.divisions = results.data;
            $log.debug("Load divisions: ", $scope.divisions);
        }, 
        function(error){
            $log.error("Failed to get divisions");
           $alerts.addAlert("danger","Error: Unable to retrieve divisions.");    
        });
        
        //Load Statuses
        $scope.memberStatuses = [];
        $global.getProperty("Status").then(function(results){
            $scope.memberStatuses = results.data;
            $log.debug("Load divisions: ", $scope.memberStatuses);
        }, 
        function(error){
            $log.error("Failed to get member statuses");
           $alerts.addAlert("danger","Error: Unable to retrieve member statuses.");    
        });
        
        $scope.dateOptions = {
            formatYear: 'yy' //use a two digit year
        };        
        
    }; // End of init()
    

    //Iterates over the search form to build the appropriate query string
    $scope.returnSearchParamaters = function(query){
        var parameters = [
            {'key':"perPage", 'value': $scope.perPage }, 
            {'key': "currentPage",'value': $scope.currentPage }
        ];
        
        //If a query is passed in, then parse it into the parameters list
        if(query){        
            angular.forEach(query, function(value, key){
                if(key === 'admittedDate'){
                    parameters.push({'key':key, 'value':value.toLocaleDateString('en-US')});
                }
                else{
                    parameters.push({'key':key, 'value':value});   
                }
            });
        }
        
        return parameters;
    };
    
    
        
        //open() displays a date picker
    //expects: $event (The mouse click)
    //returns: void - Opens the ui-bootstrap datepicker             
    $scope.open = function($event) {
        $log.debug("Open Clicked");
        $event.preventDefault();
        $event.stopPropagation();


        $scope.admittedIsOpen = true;
       
      };
        
    //Returns the MasterList based on the Query Parameters and Pagination
    $scope.getMasterList = function(query) {
        
        var parameters = $scope.returnSearchParamaters(query);        
        $log.debug("Parameters: ", parameters);
        
        var masterListRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST], parameters));
          
        masterListRequest.then(function(pl) {
                $log.debug("Masterlist loaded successfully: ", pl.data);
                
                if(pl.data){
                    $scope.masterList = pl.data.items;
                    if(pl.data.pagination){
                        $scope.totalItems = pl.data.pagination.totalResults;
                    }
                    $scope.dataLoaded = true;
                }
                
            
            }, function(err) {
                $log.log("Masterlist failed to load!")
                $log.debug(err);
            
                $scope.addAlert("danger", 'Error: Unable to retrieve members.');
            
            });
    
    };

        
    //Resets the page and submits a new search
    $scope.search = function(){
        $scope.isCollapsed = true;
        $scope.currentPage = 1;
        $scope.getMasterList($scope.query);
    }
        
    
    //Deletes a lawyer
    $scope.deleteLawyer = function(id) {
        
        var parameters = [{ 'key': "ident", 'value' : id }].concat($scope.returnSearchParamaters(100, 1, $scope.query));
        var deleteLawyerRequest = RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST], parameters));
          
        deleteLawyerRequest.then(
            function(pl) {
                $log.debug("Delete Lawyer Successful: ", id , pl.data);
                $scope.masterList = pl.data.items;
                $scope.addAlert("success", "Success: Attorney removed.");
            }, function(err) {
                $log.log("Delete Lawyer failed!")
                $log.debug(err);
                $scope.addAlert("danger", "Error: Attorney removal failed.");
            });
    };
        
    $scope.restoreLawyer = function(id) {
        var parameters = [{ 'key': "ident", 'value' : id }].concat($scope.returnSearchParamaters(100, 1, $scope.query));
        var restoreLawyerRequest = RESTService.put(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST], parameters));

        restoreLawyerRequest.then(function (pl) {
            $log.debug("Restore Lawyer Successful: ", id , pl.data);
            $scope.masterList = pl.data.items;
            $scope.addAlert("success", "Success: Attorney Restored.");
        }, function (err) {
            $log.log("Restore Lawyer failed!")
            $log.debug(err);
            $scope.addAlert("danger", "Error: Attorney restoration failed.");
        });
    };
        
    $scope.addAlert = function(type, message) {
          $alerts.addAlert(type, message);
    };

    $scope.closeAlert = function(index) {
        $alerts.closeAlert(index);
    };
    
    //ADD $watch to populate $scope.alerts
    $scope.$watch($alerts.alerts, function() {
        $scope.alerts = $alerts.alerts;
    });
    

    $scope.isActive = function(status) {
        if(status){
            return status;
        } else {
            return "Inactive";
        }
    }
    
    $scope.deletePopover = {
        title: 'Are you sure?',
        templateUrl: 'deletePopoverTemplate.html'
    };
        
    $scope.restorePopover = {
        title: 'Are you sure?',
        templateUrl: 'restorePopoverTemplate.html'
    };
            
    //Initialize
    $scope.init();
}]);