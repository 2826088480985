angular
.module('App.ReportsController', ['ui.bootstrap','ngTable','ngTableExport'])
.controller('ReportsController',[
    '$scope',
    '$log',
    '$filter',
    '$stateParams',
    'RESTService',
    '$global',
    function ($scope, $log, $filter, $stateParams, RESTService, $global) {
        
        
    //URL should look like: http://eriebar:4444/#/reports/reportName
    
        
    //Set up our controller
    $scope.init = function () {
        $log.debug("Loading ReportsController");
        $scope.alerts = []; //Contains the visible alerts
        $scope.committees = []; // Used to populate the dropdown
        $scope.getCommittees(); // Populate $scope.committees
        $scope.report = []; // 
        $scope.reportName = ""; //Display Variable for page header an error messaging
        $scope.reportEndpoint = "" //The API endpoint to get the data from
        $scope.showStartDatePicker = false; //Should we show the startdate picker?
        $scope.startDateLabel = "Start Date:"; //What do we call it?
        $scope.startDateKey = "startDate"; //What do we name the parameter that we pass to the API?
        $scope.startDateIsOpen = false; //Is the date picker visible?
        $scope.showEndDatePicker = false; //Same as above
        $scope.endDateLabel = "End Date:"; //Same as above
        $scope.endDateKey = "endDate"; //Same as above
        $scope.endDateIsOpen = false; //Same as above
        $scope.showCommitteeSelect = false; //Do we show the committee select screen?
        $scope.committeeLabel = "Committee:"; //What do we call it?
        $scope.committeeKey = "committeeIdent"; // What do we name the parameter that we pass to the API?
        $scope.startDate = ""; //This is the variable that stores the value we send to the API
        $scope.endDate = ""; //This is the variable that stores the value we send to the API
        $scope.committeeIdent = ""; //This is the variable that stores the value we send to the API
        $scope.paginatedReport = false; //Is this report paginated? If so, we handle generating the CSV differently and we display a pager
        $scope.dataLoaded; //Has the pages data loaded?
        $scope.dataExportLoaded = false; //Has the Full Data loaded for the report?
        $scope.committeeDetails = {}; //Edgecase for Committee Roster Report - Stores the second data object needed for the table and export
        $scope.data = []; //Contains the current table as an array of JSON objects
        $scope.dataExport = []; //Contains ALL of the data for this report, used to generate the CSV
        $scope.membersOnly = false;
        $scope.showCampaignContYear = false;
        $scope.membersOnlyLabel = "Show Members Only:"; 
        $scope.showMembersOnlyFlag = false;
        $scope.unpaidOnly = false;
        $scope.unpaidOnlyLabel = "Show Unpaid Pledges Only:"; 
        $scope.showUnpaidOnlyFlag = false;
        $scope.showPledgeYear = false;
        $scope.pledgeYearLabel = 'Pledge Year:';
        
        if($stateParams.perPage){
            $scope.perPage = $stateParams.perPage; //How many items do we show on a page in the table?
        } else {
            $scope.perPage = 10;
        }
        
        if($stateParams.currentPage){
            $scope.currentPage = $stateParams.currentPage; //What page of the table are we on?
        } else {
            $scope.currentPage = 1;
        }
        
        $scope.dateOptions = {
            formatYear: 'yy' //use a two digit year
        };        
        
        
        //Remember that our route controller makes all querystring variables lowercase
        switch($stateParams.reportName) { //Take the reportName out of the query string and builds the page according to the cases below
            case 'campaigncontributersbyyear':
                $scope.reportName = "Campaign Contributers By Year";
                $scope.reportEndpoint = 'GetCampaignContributorsByCampaignYear'; 
                $scope.showCampaignContYear = true;
                $scope.campaignContYearLabel = "Campaign Year:";
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'committeechairpersonreport':
                $scope.reportName = "Committee Chairperson Report";
                $scope.reportEndpoint = "GetCommitteeChairpersonReport";
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'committeerosterreport':
                $scope.reportName = "Committee Roster Report";
                $scope.reportEndpoint = "GetCommitteeRosterReport";
                $scope.showCommitteeSelect = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'directoryexportreport':
                //crashes the browser
                $scope.reportName = "Directory Export Report";
                $scope.reportEndpoint = "GetDirectoryExportReport";
                $scope.paginatedReport = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'duesinvoicereport':
                //can't find good data - needs to be tested
                $scope.reportName = "Dues Invoice Report";
                $scope.reportEndpoint = "GetDuesInvoiceReport";
                $scope.startDateKey = "invoiceDate";
                $scope.startDateLabel = "Invoice Date";
                $scope.showStartDatePicker = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'duesreceiptreport':
                $scope.reportName = "Dues Receipt Report";
                $scope.reportEndpoint = "GetDuesReceiptReport";
                $scope.showStartDatePicker = true;
                $scope.showEndDatePicker = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'electionmemberlistreport':
                $scope.reportName = "Election Member List Report";
                $log.debug("Selecting Report: " + $scope.reportName);
                $scope.reportEndpoint = "GetElectionMemberListReport";
                $scope.showStartDatePicker = true;
                $scope.startDateKey = "year";
                break;
            case 'getemailinglistreport':
                //works, needs pagination
                $scope.reportName = "Emailing List Report";
                $scope.reportEndpoint = "GetEmailingListReport";
                $scope.paginatedReport = true;
                $scope.showMembersOnlyFlag = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'mailinglistreport':
                $scope.reportName = "Mailing List Report";
                $scope.reportEndpoint = "GetMailingListReport";
                $scope.paginatedReport = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'memberstatisticsbycountyreport':
                $scope.reportName = "Member Statistics By County";
                $scope.reportEndpoint = "GetMemberStatisticsByCountyReport";
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'memberstatisticsreport':
                $scope.reportName = "Member Statistics Report";
                $scope.reportEndpoint = "GetMemberStatisticsReport";
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'masterliststatistics':
                $scope.reportName = "Masterlist Statistics Report";
                $scope.reportEndpoint = "GetMasterlistStatisticsReport";
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'pledgecarddata':
                $scope.reportName = "Pledge Card Info";
                $scope.reportEndpoint = "GetPledgeCardData";
                $scope.startDateKey = "pledgeDate";
                $scope.startDateLabel = "Pledge Year";
                $scope.showStartDatePicker = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'pledgereceiveablesreport':
                $scope.reportName = "Pledge Receiveables Report";
                $scope.reportEndpoint = "GetPledgeReceiveablesReport";
                $scope.showStartDatePicker = true;
                $scope.showEndDatePicker = true;
                $scope.showUnpaidOnlyFlag = true;
                $scope.showPledgeYear = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'unpaiddues':
                $scope.reportName = "Unpaid Dues Report";
                $scope.reportEndpoint = "GetUnpaidDuesReport";
                $scope.startDateKey = "invoiceDate";
                $scope.startDateLabel = "Invoice Date";
                $scope.showStartDatePicker = true;
                $log.debug("Selecting Report: " + $scope.reportName);
                break;
            case 'vlpreport':
                $scope.reportName = "VLP Report";
                $log.debug("Selecting Report: " + $scope.reportName);
                $scope.reportEndpoint = "GetVlpReport";
                $scope.showStartDatePicker = true;
                $scope.showEndDatePicker = true;
                break;
            default: 
                $log.debug("No report named '" + $scope.reportName + "' was found.");
        }

        //If there are no data inputs present, run the report automagically.
        if(!$scope.showStartDatePicker && !$scope.showEndDatePicker && !$scope.showCommitteeSelect && !$scope.showCampaignContYear){
            $scope.getReport($scope.reportEndpoint);
        }
        
    };

        
    
    //open() displays a date picker
    //expects: $event (The mouse click), picker (Which event picker you want to open)
    //returns: void - Opens the ui-bootstrap datepicker             
    $scope.open = function($event, picker) {
        $log.debug("Open Clicked");
        $event.preventDefault();
        $event.stopPropagation();

        if(picker == "startDate"){
            $scope.startDateIsOpen = true;
        } else if (picker == "endDate"){
            $scope.endDateIsOpen = true;
        }
      };

        
        
    //humanDate() returns a MM/DD/YYYY formatted date
    //expects: date - a javascript date object
    //returns: a string version "MM/DD/YYYY" of date 
    $scope.humanDate = function(date){
        //return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
        return date.toLocaleDateString('en-US');
    }
    
    
    
    //filename() is an accessor for $stateParams.reportName
    //expects: void
    //returns: $stateParams.reportName 
    $scope.filename = function () {
        // dynamic filename
        return $stateParams.reportName;
    };
        
        
        
    //JSONToCSVConvertor() creates a CSV and initiates a file download
    //expects:  JSONData - an Array of JSON objects that ALL contain the same parameters
    //          Report Title - A string that is the title of the report (First Line of the CSV and used in the file name)
    //          Show Label - A boolean, when true uses the keys from each parameters in the first object in the array as the headers for the CSV.
    //returns: void - creates a CSV file and initiates the file download in the browser     
    $scope.JSONToCSVConvertor = function(JSONData, ReportTitle, ShowLabel) {
        
        $log.debug("JSONtoCSV: ", JSONData, ReportTitle, ShowLabel); 
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

        
        //This is the Angular Specific Part - It removes any hidden parameters from the array. (eg. $$hash)
        var excludeTheseKeys = [];        
        //Assumes JSONData exists
        //Assumes that all objects have the same keys
        //Loops through the first element in the array and creates an array of parameters who's key starts with $
        angular.forEach(JSONData[0], function(value, key){
            console.log(key);
            if(key.indexOf("$")==0){
                excludeTheseKeys.push(key);
            }
        });
        
        //Iterates through the entire array
        angular.forEach(JSONData, function(value, key){
            
            //Removing parameters that are in the excluded list.
            for(var i = 0; i < excludeTheseKeys.length; i++){
                delete value[excludeTheseKeys[i]];
            } 
            
        });
        
        var CSV = '';    
        //Set Report title in first row or line

        CSV += ReportTitle + '\r\n\n';
        
        //if this is the committee report, add the relevant information to the top of the report
        if($stateParams.reportName == 'committeerosterreport'){
            if($scope.committeeDetails.CommitteeName){
                CSV += 'Committee Name,' + $scope.committeeDetails.CommitteeName + '\r\n';
            }
            
            if($scope.committeeDetails.ChairName){
                CSV += 'Committee Chair,' + $scope.committeeDetails.ChairName + '\r\n';
            }
            
            if($scope.committeeDetails.Liaison){
                CSV += 'Committee Liason,' + $scope.committeeDetails.Liaison + '\r\n';
            }
            
            CSV += '\n';
        }
        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row = "";

            //This loop will extract the label from 1st index of on array
            for (var index in arrData[0]) {

                //Now convert each value to string and comma-seprated
                row += index + ',';
            }

            row = row.slice(0, -1);

            //append Label row with line break
            CSV += row + '\r\n';
        }

        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + '\r\n';
        }

        if (CSV == '') {        
            alert("Invalid data");
            return;
        }   

        //Generate a file name
        //Prefix it with today's date so that they're in order if you download it again later
        var date = new Date(Date.now());
        var fileName = String(date.getFullYear()) + (((date.getMonth() + 1)<10) ? String("0"+(date.getMonth() + 1)) : String((date.getMonth() + 1))) + (((date.getDate())<10) ? String("0"+date.getDate()) : String(date.getDate())) + "_";
        
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g,"_");   

        //Initialize file format you want csv or xls
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension    

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");    
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        //link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }    


    
    //changePage() calls getReport() with the new page number
    //expects: void - $scope.currentPage is set using ng-model
    //returns: void - Helper function calls getReport() when the user clicks on a new page in the pager  
    $scope.changePage = function(currentPage){
        $scope.currentPage = currentPage;
        $log.debug("Report page changed to: ", $scope.currentPage);
        $scope.getReport($scope.reportEndpoint, $scope.startDate, $scope.endDate, $scope.committeeIdent);
    }
      
    
    
    
    
    //getReport() communicates with the API to retrieve the report data which populates the table in the view
    //expects: endPoint - A string of the last token in the URI of the reports API endpoint
    //         startDate - A string of the startDate for the report in MM/DD/YYYY format
    //         endDate - A string of the endDate for the report in MM/DD/YYYY format
    //         committeeIdent -
    //returns: void - Success: $scope.data is set to the values sent from the API on success
    //              - Failure: User alerts are generated. 
    $scope.getReport = function(endPoint, startDate, endDate, committeeIdent){
        
        $scope.dataLoaded = false;

        var parameters = [];
        
        if(startDate){
//            startDate = new Date(startDate);
//            startDate = (startDate.getMonth() + 1) + "/" + startDate.getDate() + "/" + startDate.getFullYear();
            parameters.push({'key' : $scope.startDateKey, 'value' : $scope.humanDate(new Date(startDate)) });
            $log.debug("Start Date: ", startDate);
        }
        
        if(endDate) {
//            endDate = new Date(endDate);
//            endDate = (endDate.getMonth() + 1) + "/" + endDate.getDate() + "/" + endDate.getFullYear();
            parameters.push({'key' : $scope.endDateKey, 'value' : $scope.humanDate(new Date(endDate)) });
            $log.debug("End Date: ", endDate);
        }
        
        if(committeeIdent) {
            parameters.push({'key' : $scope.committeeKey, 'value' : committeeIdent });
            $log.debug("Committees[" + committeeIdent + "]");
        }
        
        if($scope.paginatedReport){
            parameters.push({'key' : 'perPage', 'value' : $scope.perPage });
            parameters.push({'key' : 'currentPage', 'value' : $scope.currentPage });
            $log.debug("Pagination[ currentPage: " + $scope.currentPage + ", prePage: " + $scope.perPage + "]");
        }
        
        if($scope.membersOnly){
            parameters.push({'key' : 'membersOnly', 'value' : $scope.membersOnly });
            $log.debug("membersOnly[" + $scope.membersOnly + "]");
        }
        
        if($scope.showCampaignContYear){
            parameters.push({'key' : 'year', 'value' : $scope.campaignContYear });
            $log.debug("year[" + $scope.campaignContYear + "]");
        }
        
        
        if($scope.unpaidOnly){
            parameters.push({'key' : 'unpaidOnly', 'value' : $scope.unpaidOnly });
            $log.debug("unpaidOnly[" + $scope.unpaidOnly + "]");
        }


        if($scope.showPledgeYear){
            parameters.push({'key' : 'year', 'value' : $scope.pledgeYear });
            $log.debug("year[" + $scope.pledgeYear + "]");
        }

        

        var getReportRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.REPORTS, endPoint], parameters));
          
        getReportRequest.then(function(pl) {
                $log.debug("Get Report Data Successful: ", pl.data);

                if(pl.data){

                    $scope.dataExportLoaded = false; // need to reset this status on each search
                    
                    if(pl.data.length>0 || pl.data.Members || pl.data.items){ 
                    
                        //Edgecase for committee roster report
                        if(pl.data.Members) { //Committee Roster Report returns two data objects
                            $scope.data = pl.data.Members;
                            $scope.committeeDetails = pl.data.Committee;
                    
                        } else if(pl.data.items) { //If the report uses pagination, the array of objects is stored in pl.items
                            $scope.data = pl.data.items;
                            
                            if(pl.data.pagination){
                                $scope.totalItems = pl.data.pagination.totalResults;
                            }
                            
                        } else {
                            $scope.data = pl.data; // Generic reports without pagination store the array of objects in pl.data
                        }

                        $log.debug("$scope.data: ", $scope.data);

                    } else {

                        $scope.data = [];

                        var errorMessage = "There is no data available in " + $scope.reportName;
                        if($scope.showCommitteeSelect){
                            var index = 0;

                            //translate from Ident to index
                            angular.forEach($scope.committees, function(value, key){
                                if($scope.committeeIdent == value.Ident){
                                    index = key;
                                }
                            });

                            errorMessage += " for " + $scope.committees[index].CommitteeName;
                        }
                        if($scope.showStartDatePicker){
                            errorMessage += " from " + startDate;
                        }
                        if($scope.showEndDatePicker){
                            errorMessage += " to " + endDate;
                        }
                        
                        $scope.addAlert("danger", errorMessage);

                    }

                    // Hide loading icon
                    $scope.dataLoaded = true;
                }
                
                // Turn off loading icon
               // $scope.dataLoaded = true;
            
            }, function(err) {

                $log.log("Get Report Data Failed!")
                $log.debug(err);
            
                $scope.addAlert("danger", "There was a problem retrieving " + $scope.reportName + ".");

                // Turn off loading icon
                $scope.dataLoaded = true;
            
            }).then( //After the initial page load, load the full dataset for CSV export
                function(pl){
                    $scope.getCSVData(endPoint, startDate, endDate, committeeIdent);//Load All of our data so the CSV can be generated
                },
                function(err){

                });
    }
        
    
    
    
    
    //getCSVData() communicates with the API to retrieve ALL of the data available for a report, for use in the CSV export
    //expects: endPoint - A string of the last token in the URI of the reports API endpoint
    //         startDate - A string of the startDate for the report in MM/DD/YYYY format
    //         endDate - A string of the endDate for the report in MM/DD/YYYY format
    //         committeeIdent -
    //returns: void - Success: $scope.dataExport are set to the values sent from the API on success
    //              - Failure: User alerts are generated. 
    $scope.getCSVData = function(endPoint, startDate, endDate, committeeIdent){
        $log.debug("Running getCSVData()");
        if(!$scope.dataExportLoaded){ //skip loading the full data again when user clicks on pager.
            if($scope.paginatedReport){ //Only make this request if the report is paginated
                        //create the parameters
                        var exportQueryParameters = [];

                        if(startDate){
                            exportQueryParameters.push({'key' : $scope.startDateKey, 'value' : $scope.humanDate(new Date(startDate)) });
                            //$log.debug("Start Date: ", startDate);
                        }

                        if(endDate) {
                            exportQueryParameters.push({'key' : $scope.endDateKey, 'value' : $scope.humanDate(new Date(endDate)) });
                            //$log.debug("End Date: ", endDate);
                        }

                        if(committeeIdent) {
                            exportQueryParameters.push({'key' : $scope.committeeKey, 'value' : committeeIdent });
                            //$log.debug("Committees[" + committeeIdent + "]");
                        }
                
                        if($scope.membersOnly){
                            exportQueryParameters.push({'key' : 'membersOnly', 'value' : $scope.membersOnly });
                            $log.debug("membersOnly[" + $scope.membersOnly + "]");
                        }


                        if($scope.unpaidOnly){
                            exportQueryParameters.push({'key' : 'unpaidOnly', 'value' : $scope.unpaidOnly });
                            $log.debug("unpaidOnly[" + $scope.unpaidOnly + "]");
                        }
                
                
                        if($scope.showCampaignContYear){
                            parameters.push({'key' : 'year', 'value' : $scope.campaignContYear });
                            $log.debug("year[" + $scope.campaignContYear + "]");
                        }


                        if($scope.showPledgeYear){
                            parameters.push({'key' : 'year', 'value' : $scope.pledgeYear });
                            $log.debug("year[" + $scope.pledgeYear + "]");
                        }

                        if(angular.isDefined($scope.totalItems)){
                            if($scope.paginatedReport){    
                                exportQueryParameters.push({'key' : 'perPage', 'value' : $scope.totalItems });
                                 exportQueryParameters.push({'key' : 'currentPage', 'value' : 1 });
                                //$log.debug("Pagination[ currentPage: " + $scope.currentPage + ", prePage: " + $scope.perPage + "]");
                            }
                        }
                        $log.debug("Full Data Load Parameters: ", exportQueryParameters);

                var getAllDataRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.REPORTS, endPoint], exportQueryParameters));

                getAllDataRequest.then(
                    function(pl) {
                        $log.debug("Get Full Data Load Successful: ", pl.data);

                        if(pl.data){
                            $scope.dataExport = pl.data.items;        
                            $scope.dataExportLoaded = true;
                        } else {

                            var errorMessage = "Error loading CSV Export for " + $scope.reportName;
                            if($scope.showCommitteeSelect){
                                var index = 0;

                                //translate from Ident to index
                                angular.forEach($scope.committees, function(value, key){
                                    if($scope.committeeIdent == value.Ident){
                                        index = key;
                                    }
                                });

                                errorMessage += " for " + $scope.committees[index].CommitteeName;
                            }
                            if($scope.showStartDatePicker){
                                errorMessage += " from " + startDate;
                            }
                            if($scope.showEndDatePicker){
                                errorMessage += " to " + endDate;
                            }

                            $scope.addAlert("danger", errorMessage);
                        }
                    }, 
                    function(err){
                          $log.log("Get Data Export Failed!")
                          $log.debug(err);

                          $scope.addAlert("danger", "There was a problem loading Data Export for " + $scope.reportName + ".");
                    }
                );
            } else { //All the data was loaded in the original page load
                $scope.dataExport = $scope.data;
                $scope.dataExportLoaded = true;
            }
        }//End of !dataExportLoaded
    }
    
    
    //getCommitees is used to look up the possible committees
    //expects: 
    //returns: void - sets $scope.committees to the payload returned from the API.  It includes key value pairs of committee idents and names.
    $scope.getCommittees = function() {
        
        var getCommitteesRequest = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE]));
          
        getCommitteesRequest.then(function(pl) {
                $log.debug("Get Committees Successful: ", pl.data);
                
                if(pl.data){
                    $scope.committees = pl.data;
                }
                
            
            }, function(err) {
                $log.log("Get Commitees Failed!")
                $log.debug(err);
            
                $scope.addAlert("danger", "There was a problem loading committees.");
            
            });
    };    
    
    
        
        
    //addAlert adds a new alert object to the $scope.alerts array
    //expects: type is a bootstrap class for alerts (eg. success, warning, danger, info), message is the message you want displayed in the alert box
    //returns: void - adds a new alert object to the $scope.alerts array
    $scope.addAlert = function(type, message) {
          $scope.alerts.push({msg: message, type:type});
    };

    //closeAlert closes the alert box in the view model
    //expects: index is the position in the array of the box you want to close
    //returns: void - removes the item at index out of the $scope.alerts array
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };

            
    //Initialize
    $scope.init();
}]);


