'use strict'

angular
.module('App.HeaderController', [])
.controller('HeaderController',[
    '$rootScope',
    '$scope',
    '$log',
    '$global',
    'identity',
    '$state',
function ($rootScope, $scope, $log, $global, identity, $state) {
    
    $scope.init = function () {
        
        $log.debug("Loading HeaderController");
        $rootScope.menuToggle = true;
        $scope.userName = $global.settings.fullName;

        
        $scope.readOnly = false;

        // 2= readonly
        if (identity.getRoles() == 2){

            $scope.readOnly = true;

        };
    }
        
        
    $scope.logout = function(){
        identity.logout();
        $state.go("login");
    }

    $scope.toggleMenu = function(){
        $rootScope.menuToggle = !$rootScope.menuToggle;
    }
    
    //Initialize
    $scope.init();
}]);
