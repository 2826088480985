'use strict'

angular
.module('App.DuesInvoicingController', [])

.controller('DuesInvoicingController',[
    '$scope',
    '$log',
    'RESTService',
    '$global', 
function ($scope, $log, RESTService, $global) {
    
    $scope.init = function(){
        $scope.dateOptions = {
            formatYear: 'yy'
        };
        $scope.alerts = [];
        $scope.invoiceDate; 
        $scope.invoices = [];
        $scope.getInvoices();
        $scope.dataLoaded = false;
    };
    
    
    $scope.getInvoices = function(){
        
        var parameters = [{ 'key': "tableName", 'value' : "Status" }];
        
        var resultSet = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.DOMAIN], parameters));
            
        resultSet.then(function(results){
            if(results.data){
                
                angular.forEach(results.data, function(status){
                    $scope.invoices.push({
                        MasterListStatus : status.Name1,
                        MasterListStatusIdent: status.Ident,
                        DuesInc: 0.00, 
                        AssessmentInc: 0.00,
                        ContributingInc: 0.00,
                        VlpInc: 0.00
                    });
                });

                // Turn off loading icon
                $scope.dataLoaded = true;
            }
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve Invoices.' });

           // Turn off loading icon
           $scope.dataLoaded = true;
        });

    };
    
    $scope.generateInvoices = function(){
        
        var parameters = [{ 'key': "invoiceDate", 'value' : $scope.invoiceDate.toLocaleDateString('en-US') }];
        
        var added = RESTService.post(
            RESTService.getControllerPath([$global.RESTControllerNames.INVOICE], parameters),
            $scope.invoices
        );
        
        added.then(function(response){
            $scope.alerts.push({ type: 'success', msg: 'Success: Invoices created.' });
        }, function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to create Invoices.' });
        });
        
    };

    $scope.open = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened = true;
    };
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    $scope.init();
    
}]);

