'use strict'

angular
.module('App.PledgeSearchController', [])

.controller('PledgeSearchController',[
    '$scope',
    '$log',
    'RESTService',
    '$global',
    '$state', 
    '$stateParams',
    'identity',
function ($scope, $log, RESTService, $global, $state, $stateParams, identity) {
    
    //List of pledges for the selected member
    $scope.memberPledges = {};
    //List of all master list members
    $scope.masterListMembers = {};
    //List of user alerts
    $scope.alerts = [];
    //Page header
    $scope.header = 'Pledge Search';
    $scope.dataLoaded;

    
    $scope.readOnly = false;

    // 2= readonly
    if (identity.getRoles() == 2){

        $scope.readOnly = true;

    };

    /**
     * Loads pledges when the user selects a member from the typeahead. 
    */
    $scope.$watch('member', function () {
        if ($scope.member && $scope.member.Ident > 0) {
            $scope.getAllMemberPledges($scope.member.Ident);
            $scope.header = 'Pledge Search - ' + $scope.member.FullName;
        }
    });        
    
    /**
     * Loads all master list members for the member typeahead.
     * Note: Sets the selected member if we are navigating back to this view from add/edit
    */
    $scope.getMasterListMembers = function(){
        $scope.masterListMembers = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST]));
        
        $scope.masterListMembers.then(function(results){
            $scope.masterListMembers = results.data;
        
            
            //If we are navigation back to this page we need to reload the selected member
            if($stateParams.masterListIdent > 0){
                angular.forEach($scope.masterListMembers, function(masterListMember){
                    if($stateParams.masterListIdent == masterListMember.Ident){
                        $scope.member = masterListMember;
                        return;
                    }
                });
            }
        }, 
        function(error){
           $scope.alerts.push({ type: 'Danger', msg: 'Error: Unable to retrieve members.' });    
        });
        
    };
    
    /**
     * Rereives all pledges after a member has been selected.
    */
    $scope.getAllMemberPledges = function(masterListIdent){
        
        $scope.dataLoaded = false;

        if(masterListIdent){
        
            var parameters = [
                {'key': "masterListIdent", 'value': masterListIdent },
                {'key': "perPage", 'value': 1000 }, 
                {'key': "currentPage", 'value': 1 }
            ];

            var results = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.PLEDGE], parameters));
            results.then(function(results){ 
                $scope.memberPledges = results.data.items;
                $log.debug("Get All Member Pledges: ", results.data.items);
                $state.transitionTo('site.pledge', {masterListIdent:masterListIdent});

                $scope.dataLoaded = true;
            }, 
            function(error){
               $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve pledges.' });

               $scope.dataLoaded = true;
            });
        }
        
    };
    
    $scope.editPledge = function(ident){
        
        if($scope.member){
            $state.go('site.pledge.update', {ident:ident});
        }
        else{
            $scope.alerts.push({ type: 'danger', msg: 'Please select a member' });   
        }
    };

    $scope.deletePledge = function(ident, masterListIdent){
        
        var parameters = [
            {'key': "ident", 'value': ident },
            {'key': "masterListIdent", 'value': masterListIdent },
            {'key': "perPage", 'value': 1000 }, 
            {'key': "currentPage", 'value': 1 }
        ];
        
        var deleteSuccess = RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.PLEDGE], parameters));
            
        deleteSuccess.then(function(result){
            //Refresh the grid to reflect the deletion
            $scope.memberPledges = result.data.items;
            $scope.alerts.push({ type: 'success', msg: 'Success: Pledge removed.' });
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Pledge removal failed.' });
        });
    };
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    $scope.getMasterListMembers();
    
    if($stateParams.masterListIdent > 0){
        $scope.getAllMemberPledges($stateParams.masterListIdent); 
    }
    
}]);

