'use strict'

angular
.module('App.CommitteeController', [])
.controller('CommitteeSearchController',[
    '$scope',
    '$log',
    'RESTService',
    '$global',
    '$state',
function ($scope, $log, RESTService, $global, $state) {

    $scope.committees = {};
    $scope.alerts = [];
    $scope.dataLoaded = false;
    
    $scope.getCommittees = function(){
        
        var searchResults = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE]));
        searchResults.then(function(results){
            $scope.committees = results.data;

            // Turn off loading icon
            $scope.dataLoaded = true;
        }, 
        function(error){
           $scope.alerts.push({ type: 'success', msg: 'Error: Unable to retrieve committees.' });

           // Turn off loading icon
           $scope.dataLoaded = true;
        });
    };
    
    $scope.deleteCommittee = function(ident){
        var deleteSuccess = RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE]), ident);
        deleteSuccess.then(function(result){
            //Refresh the grid to relect the deletion
            $scope.committees = result.data;
            $scope.alerts.push({ type: 'success', msg: 'Success: Committee removed.' });
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Committee removal failed.' });
        });
    };
    
    $scope.editCommittee = function(ident){
        $state.go('site.committee.update', {ident:ident});
    };
    
    $scope.viewMembers = function(ident){
        $state.go('site.committee.committeemembers', {ident:ident});
    };
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    $scope.getCommittees();
}]);

