'use strict'

angular
.module('App.CommitteeMembersController', [])
.controller('CommitteeMembersController',[
    '$scope',
    '$stateParams', 
    'RESTService',
    '$global',
function ($scope, $stateParams, RESTService, $global) {

    $scope.init = function(){
        $scope.committeeMembers = null;
        $scope.masterListMembers = null;
        $scope.alerts = [];
        $scope.getCommittee();
        $scope.getCommitteeMembers();
        $scope.getMasterListMembers();
        $scope.dataLoaded = false;
    };
    
    $scope.getCommitteeMembers = function(){
        
        var searchResults = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEEMEMBER]), 
            $stateParams.ident
        );
        
        searchResults.then(function(results){ 
            $scope.committeeMembers = results.data;

            // Turn off loading icon
            $scope.dataLoaded = true;
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve Committee Members.' });

            // Turn off loading icon
            $scope.dataLoaded = true;
        });
        
    };
    
    $scope.getCommittee = function(){
        
        var searchResults = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEE]), 
            $stateParams.ident
        );
        
        searchResults.then(function(results){ 
            $scope.header = 'Committee Members - ' + results.data.CommitteeName;
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve Committee.' });
        });
        
    };
    
    $scope.getMasterListMembers = function(){
      
        var parameters = [{ 'key': "committeeIdent", 'value' : $stateParams.ident }];
        
        $scope.masterListMembers = RESTService.get(
            RESTService.getControllerPath([$global.RESTControllerNames.MASTERLIST], parameters)
        );
        
        $scope.masterListMembers.then(function(results){
            $scope.masterListMembers = results.data;
        }, 
        function(error){
           $scope.alerts.push({ type: 'danger', msg: 'Error: Unable to retrieve members.' });
        });
        
    };
    
    $scope.addCommitteeMember = function(){
        if($scope.masterList.Ident){
        
            var parameters = [{ 'key': "committeeIdent", 'value' : $stateParams.ident }];
            
            var committeeMember ={
                MasterListIdent: $scope.masterList.Ident, 
                CommitteeIdent: $stateParams.ident
            };

            var addSuccess = RESTService.post(
                RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEEMEMBER], parameters),
                committeeMember
            );

            addSuccess.then(function(response){
                $scope.alerts.push({ type: 'success', msg: 'Success: Committee Member added.' });
                $scope.committeeMembers = response.data;
                $scope.getMasterListMembers();
                $scope.masterList = null;
            }, 
            function(error){
                $scope.alerts.push({ type: 'danger', msg: 'Error: Committee Member add failed.' });            
            });
            
        }
    };
    
    $scope.deleteCommitteeMember = function(ident){
        
        var parameters = [
            { 'key': "committeeIdent", 'value' : $stateParams.ident },
            { 'key': "ident", 'value' : ident }
        ];
        
        var removal = RESTService.delete(RESTService.getControllerPath([$global.RESTControllerNames.COMMITTEEMEMBER], parameters));
        removal.then(function(result){
            $scope.committeeMembers = result.data;      
            $scope.getMasterListMembers();
            $scope.alerts.push({ type: 'success', msg: 'Success: Committee Member removed.' });
        }, 
        function(error){
            $scope.alerts.push({ type: 'danger', msg: 'Error: Committee Member removal failed.' });
        });
    };
    
    $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };
    
    $scope.init();
    
}]);

