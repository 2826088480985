'use strict'

angular
.module('App.NavigationController', [])
.controller('NavigationController',[
    '$scope',
    '$log',
    '$global',
    'RESTService',
function ($scope, $log, $global, RESTService) {


    //Expects: The server looks at the logged in users identity and determines what items to return based on the users roles
    //Returns: Void, Sets $scope.links equal to an array of JSON menu items
    //The HTML then parses the array and builds the navigation based on the contents of $scope.links
    $scope.getMenu = function(){
        
        var menuGet = RESTService.get(RESTService.getControllerPath([$global.RESTControllerNames.NAVIGATION]));
          
        menuGet.then(function(pl) {
                $log.debug("Navigation loaded successfully: ", pl.data);
                //is the user logged in?
                if(pl.data){
                    $scope.links = pl.data;
                }
                
            
            }, function(err) {
            
                $log.debug("Failed to load navigation: ", err);
            
                //The request failed, lets deauth them just in case.
            
            });
          
    }
    
// Mock Data for Testing or review
//    $scope.links = 
//        [
//            { 
//                'displayName' : 'Home',
//                'sref' : 'site.home',
//                'className' : '',
//                'iconClasses' : 'fa fa-home'               
//            },
//            { 
//                'className' : 'divider'               
//            },
//            {
//                'displayName' : 'Content Pages',
//                'className' : 'dropdown',
//                'iconClasses' : 'fa fa-book',
//                'children' : [
//                                
//                                {
//                                    'displayName' : 'Login',
//                                    'sref' : 'login',
//                                    'iconClasses' : 'fa fa-key'  
//                                },
//                                { 
//                                    'displayName' : 'Change Password',
//                                    'sref' : 'site.changePassword',
//                                    'iconClasses' : 'fa fa-exchange'               
//                                },
//                                { 
//                                    'displayName' : 'Forgot Password',
//                                    'sref' : 'site.forgotPassword',
//                                    'iconClasses' : 'fa fa-question'               
//                                }
//                            ]  
//            }
//        ];
    
    $scope.hasChildren = function(node){
        if(node.children){
            return 'dropdown';
        } else {
            return '';
        }
    }

    
    $scope.init = function () {
        $log.debug("Loading NavigationController");
        $scope.links = [];
        $scope.getMenu();
    };

    //Initialize
    $scope.init();
}]);
