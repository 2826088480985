angular
.module('theme.manage-users', [])
.controller('ManageUsersController',
[   '$scope',
    '$global',
    '$location',
    'progressLoader',
    'templateCompiler',
    'crudService',
    'asUserAppService',
function ($scope, $global, $location, progressLoader, templateCompiler, crudService, asUserAppService) {

    $scope.currentRecord = null;
    $scope.editNewRecord = false;

    $scope.currentSysRole = null;
    $scope.userFilter = '';

    $scope.users = [];
    $scope.sysRoles = [];
    $scope.accountStatus = null;
    $scope.alerts = [];
    $scope.adminPractices = [];

    $scope.init = function () {
        $scope.loadAllSysRoles();
        $scope.fullLoadAllASUsers();
    };

    $scope.fullLoadAllASUsers = function () {
        progressLoader.start();
        progressLoader.set(38);

        $scope.makeLoadASUserCall();
    };

    $scope.continuedLoadAllASUsers = function () {
        $scope.users = null;
        $scope.makeLoadASUserCall();
    };

    //I broke this out to be called from two places,
    //so we can use the progressLoader correctly on page load and after a webservice call
    $scope.makeLoadASUserCall = function () {
        var asUserGetAll = crudService.getAll($global.crudControllerNames.ASUSER);
        //this should be null as long as they're not coming back from user app config
        var asUserAppConfigIdent = asUserAppService.getASUserIdent();

        asUserGetAll.then(function (pl) {
            progressLoader.set(75);
            
            if (asUserAppConfigIdent != null) { //navigated back to from user app config controller
                angular.forEach(pl.data, function (value, key) {
                    if (angular.equals(asUserAppConfigIdent, value.ident)) {
                        $scope.setCurrentRecord(value); //set user back to the user they were configuring
                        asUserAppService.clear();
                    }
                });
            }
            else {
                $scope.users = pl.data;
            }

            progressLoader.end();
        },
        function (errorPl) {
            progressLoader.end();
        });
    };

    $scope.loadAllSysRoles = function () {
        $scope.sysRoles = [];

        var sysRoleGetAll = crudService.getAll($global.crudControllerNames.SYSROLE);

        sysRoleGetAll.then(function (pl) {
            $scope.sysRoles = pl.data;
        },
        function (errorPl) {
        });
    };

    $scope.updateCurrentRecord = function () {
        progressLoader.start();
        progressLoader.set(25);

        var asUserUpdatePost = crudService.post($global.crudControllerNames.ASUSER, $scope.currentRecord);

        asUserUpdatePost.then(function (pl) {
            progressLoader.set(50);
            if (angular.equals("false", pl.data)) {
                $scope.showDuplicateAlert();
                progressLoader.end();
            }
            else {
                // clear any alerts from the scope
                $scope.alerts = [];
                //$scope.adminPracticeIdent = -1;
                $scope.currentRecord = null;
                $scope.continuedLoadAllASUsers();
            }
        },
        function (errorPl) {
            progressLoader.end();
        });
    };

    $scope.deleteRecord = function (ident) {
        bootbox.confirm("Are you sure you want to remove this user?", function (result) {
            if (angular.equals(true, result)) {
                progressLoader.start();
                progressLoader.set(25);

                var asUserDelete = crudService.delete($global.crudControllerNames.ASUSER, ident);

                asUserDelete.then(function (pl) {
                    progressLoader.set(50);
                    $scope.continuedLoadAllASUsers();
                },
                function (errorPl) {
                });
            }
        });
    };

    $scope.addCurrentRecord = function () {
        if (!$scope.editNewRecord) return;

        progressLoader.start();
        progressLoader.set(25);

        var asUserAddPost = crudService.post($global.crudControllerNames.ASUSER, $scope.currentRecord);

        asUserAddPost.then(function (pl) {
            progressLoader.set(50);
            if (angular.equals("false", pl.data)) {
                $scope.showDuplicateAlert();
                progressLoader.end();
            }
            else {
                // clear any alerts from the scope
                $scope.alerts = [];
                $scope.currentRecord = null;
                $scope.editNewRecord = false;
                $scope.continuedLoadAllASUsers();
            }
        },
        function (errorPl) {
            progressLoader.end();
        });
    };

    $scope.setCurrentRecord = function (user) {
        $scope.currentRecord = angular.copy(user);
    };

    $scope.showDuplicateAlert = function () {
        $scope.alerts.push({ msg: '<i class="fa fa-exclamation-circle"></i> A User with this Username or Email already exists.', type: "danger" });
    };

    $scope.addRecord = function () {
        $scope.currentRecord =
        {
            ident: 0,
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            eMail: '',
            systemRole: 'User',
            mustChangePassword: false
        }

        $scope.editNewRecord = true;
    };

    $scope.openAccountStatusModal = function (ident) {
        var asUserAccountStatusGet = crudService.get($global.crudControllerNames.ASUSER, ident);

        asUserAccountStatusGet.then(function (pl) {
            if (!angular.equals("", pl.data) && !angular.equals("null", pl.data)) {
                $scope.accountStatus = pl.data;
            }
        },
        function (errorPl) {
        });

        bootbox.dialog({
            title: 'Account Status',
            closeButton: false,
            message: templateCompiler.getCompiledHTML($scope, 'asuser-account-status-modal.html'),
            buttons: {
                success: {
                    label: "OK",
                    className: "btn btn-success",
                    callback: function () {
                        $scope.accountStatus = null;
                    }
                }
            }
        });
    };

    $scope.$watch('accountStatus.userIsLocked', function (newVal, oldVal) {
        //only fire if user changed the value, not on init of controller or open of modal
        if (!angular.isUndefined(newVal) && !angular.isUndefined(oldVal)) {
            var putObj = {
                lock: newVal,
                ident: $scope.currentRecord.ident
            };

            var asUserLockPut = crudService.put($global.crudControllerNames.ASUSER, putObj.ident, putObj);

            asUserLockPut.then(function (pl) {
                if (angular.equals("false", pl.data)) {
                }
            },
            function (errorPl) {
            });
        }
    });

    $scope.saveUser = function () {
        if ($scope.editNewRecord == true) {
            $scope.addCurrentRecord();
        }
        else {
            $scope.updateCurrentRecord();
        }
    };

    $scope.cancelCurrentRecord = function () {
        // clear any alerts from the scope
        $scope.alerts = [];

        $scope.currentRecord = null;
        $scope.editNewRecord = false;

        $scope.fullLoadAllASUsers();
    };

    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };

    $scope.sendUserEmail = function () {
        progressLoader.start();
        progressLoader.set(50);

        var forgotPassPost = crudService.post($global.crudControllerNames.FORGOTPASS, { email: $scope.currentRecord.eMail });

        forgotPassPost.then(function (pl) {
            $scope.alerts.push({ msg: 'An email containing a link to reset your password has been sent to the provided address.', type: "success" });
            progressLoader.end();
        },
        function (errorPl) {
            $scope.alerts.push({ msg: '<i class="fa fa-exclamation-circle"></i> We are unable to send an email to the provided address at this time. Please contact support at (315) 671-2241 or support@healtheconnections.org.', type: "danger" });
            progressLoader.end();
        });
    };

    $scope.getEmailText = function () {
        if (angular.equals('1900-01-01T00:00:00', $scope.currentRecord.lastSuccessfulLogin)) {
            return 'Send New User Email';
        }
        else {
            return 'Send Reset Password Email';
        }
    };

    //Initialize
    $scope.init();
}]);