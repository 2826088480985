'use strict';

angular
.module('App', [
    'templates-app',
    'App.Services',
    'App.LoginController',
    'App.UserManagement',
    'App.ForgotPasswordController',
    'App.DashboardController',
    'App.NavigationController',
    'App.MasterListController',
    'App.MasterListCommitteesController',
    'App.AccessDenied',
    'App.PledgeSearchController',
    'App.PledgeUpdateController', 
    'App.ChangePasswordController',
    'App.MustChangePasswordController',
    'App.CommitteeController',
    'App.CommitteeUpdateController', 
    'App.CommitteeMembersController',
    'App.MaintenanceController',
    'App.HeaderController',
    'App.DuesController',
    'App.DuesInvoicingController', 
    'App.ReportsController',
    'App.UsersController',
    'ui.bootstrap',
    'ui.router',
    'ngAria'
])
.controller('MainController',
[   '$scope',
    '$log',
    '$location',
    'identity',
    '$global',
function ($scope, $log, $location, identity, $global) {

//    I left this here in case someone needs it in the future. - WCD
//    
//    $scope.$on('$viewContentLoaded', function(event){
//        $log.debug("This fires when the content loads");
//    });

    //Returns: the state of the user
    $scope.isLoggedIn = function(){
        return identity.isAuthenticated();
    }
    
    $scope.isMustChangePassword = function() {
        return identity.mustChangePassword();
    }
    
    //Intializes App
    $scope.init = function(){
        $log.debug("initializing app.js");
    }


    $scope.init();
    
    

}]) //end controller
.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
    
    
    //All paths in the router should be lowercase
    $urlRouterProvider.rule(function ($injector, $location) {
       //what this function returns will be set as the $location.url
        var path = $location.path(), normalized = path.toLowerCase();
        if (path != normalized) {
            //instead of returning a new url string, I'll just change the $location.path directly so I don't have to worry about constructing a new url string and so a new state change is not triggered
            $location.replace().path(normalized);
        }
        // because we've returned nothing, no state change occurs
    });
            

    
    //Current Version is based on: http://stackoverflow.com/questions/22537311/angular-ui-router-login-authentication
    
      //TODO:  We could refactor this so that both Nav and Routing are database driven
        //http://blog.brunoscopelliti.com/how-to-defer-route-definition-in-an-angularjs-web-app/
        //http://stackoverflow.com/questions/25494157/angular-ui-router-dynamic-states-get-double-slashes-when-navigated-to-with-ui
        
        //Other helpful docs: https://github.com/angular-ui/ui-router/wiki/Multiple-Named-Views#view-names---relative-vs-absolute-names
      
    // Now set up the states
    //Note: 'url': '^/absolutepath', Prefixix with ^ Triggers "Absolute URL Matching", which gets rid of the extra slash caused by the parent-child relationship
    
    
    //.state can be abstract and can have children, children are determined using the . notation (eg. site.child)
    //views:  is an object that contains the 3 partial views of the page "content", "navigation", "header"
    //resolve: is where the magic happens, letting us know if the user is authorized to access this view
    //data: is an object that contains the key roles : which is an array of roles that allowed to access the page.
    //url: is the location or path that will resolve to that state.  Children are appened to parents. ^ provides an absolute reference
    //      ie. localhost/#/parent/ | state: "parent" | url: '/parent'
    //          localhost/#/parent/child  | state: "parent.child" | url : '/child'
    //          localhost/#/absoluteurl | state: "site.abs' | url : '^/absoluteurl'  (This is still a child state of parent).
    
    //Abstract state for Committee Related States
    $stateProvider
            
    
            //Abstract state for "user land"
            .state('site', {
              abstract: true,
              views: {
                           'content': { 
                                templateUrl: 'Dashboard/dashboard.html',
                                controller: 'DashboardController'
                            },
                            'navigation': {
                                templateUrl: 'Navigation/navigation.html',
                                controller: 'NavigationController'
                            },
                            'header': {
                                templateUrl: 'Header/header.html',
                                controller: 'HeaderController'
                            }
                       },
                resolve: {
                    authorize: ['authorization', '$log',
                      function(authorization, $log) {
                        $log.debug("Resolve authorization", authorization.authorize());
                        return authorization.authorize();
                      }
                    ]
                }
            })
    
    
    
            //Load the Default Dashboard
            .state('site.home', {
                url: '/', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                                templateUrl: 'MasterList/master-list_search.html',
                                controller: 'MasterListSearchController'
                    },
                }
            })
    
    
            //Search view for committees    
            .state('site.committee', {
              url: '/committee',
              views: {
                            'content@': { 
                                templateUrl: 'Committee/committee_search.html',
                                controller: 'CommitteeSearchController'
                            }
                       },
                resolve: {
                    authorize: ['authorization', '$log',
                      function(authorization, $log) {
                        $log.debug("Resolve authorization", authorization.authorize());
                        return authorization.authorize();
                      }
                    ]
                }
            })
    
    
    
            //State for updating committee settings
            .state('site.committee.update', {
                url: '/update/{ident:int}', 
                data: {
                  roles: [1]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Committee/committee_add-update.html',
                                controller: 'CommitteeUpdateController'
                    }
                }
            })
    
            
            //State for committee members
            .state('site.committee.committeemembers', {
                url: '/committeemembers/{ident:int}', 
                data: {
                  roles: [1]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Committee/committee-members.html',
                                controller: 'CommitteeMembersController'
                    }
                }
            })
            
    
            //State for searching master list
            .state('site.masterlist', {
                url: '/masterlist', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                                templateUrl: 'MasterList/master-list_search.html',
                                controller: 'MasterListSearchController'
                    }
                }
            })
    

            //State for searching master list
            .state('site.masterlist.update', {
                url: '/update/{ident:int}', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                                templateUrl: 'MasterList/master-list_add-update.html',
                                controller: 'MasterListUpdateController'
                    }
                }
            })
    
            
            //State for searching master list
            .state('site.masterlist.committee', {
                url: '/committee/{masterListIdent:int}', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                                templateUrl: 'MasterList/master-list-committees.html',
                                controller: 'MasterListCommitteesController'
                    }
                }
            })
    
            //State for searching master list
            .state('site.users', {
                url: '/users', 
                data: {
                  roles: [1]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Users/users_search.html',
                                controller: 'UsersSearchController'
                    }
                }
            })
    

            //State for searching master list
            .state('site.users.update', {
                url: '/update/{ident:int}', 
                data: {
                  roles: [1]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Users/users_add-update.html',
                                controller: 'UsersUpdateController'
                    }
                }
            })
            //State for pledge list
            .state('site.pledge', {
                url: '/pledge/{masterListIdent:int}', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Pledge/pledge_search.html',
                                controller: 'PledgeSearchController'
                    }
                }
            })
    
            
            .state('site.pledge.update', {
                url: '/update/{ident:int}', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                        templateUrl: 'Pledge/pledge_add-update.html',
                        controller: 'PledgeUpdateController'                                        }   
                }
            })


            //State for reports
            .state('site.reports', {
                url: '/reports/:reportName?perPage&currentPage', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Reports/reports.html',
                                controller: 'ReportsController'
                    }
                }
            })
    

            //Maintenance view    
            .state('site.maintenance', {
              url: '/maintenance',
              views: {
                            'content@': { 
                                templateUrl: 'Maintenance/maintenance.html',
                                controller: 'MaintenanceController'
                            }
                       },
                resolve: {
                    authorize: ['authorization', '$log',
                      function(authorization, $log) {
                        $log.debug("Resolve authorization", authorization.authorize());
                        return authorization.authorize();
                      }
                    ]
                }
            })
            
    
            //State for dues
            .state('site.dues', {
                url: '/dues/{ident:int}/page/{currentPage:int}', 
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Dues/dues.html',
                                controller: 'DuesSearchController'
                    }
                }
            })
    
                
            //State for dues
            .state('site.invoicing', {
                url: '/invoicing', 
                data: {
                  roles: [1]
                },
                views: {
                    'content@': { 
                                templateUrl: 'Dues/dues_invoicing.html',
                                controller: 'DuesInvoicingController'
                    }
                }
            })            
    
            
            //Password Change
            .state('site.changePassword', {
                url: '/changepassword',
                data: {
                  roles: [1,2]
                },
                views: {
                    'content@': {
                        templateUrl: 'ChangePassword/changepassword.html',
                        controller: 'ChangePasswordController'
                    }
                }
            })
      
    
    
    
            //Password Reset
            .state('site.forgotPassword', {
                url: '/forgotpassword',
                data: {
                  roles: [0,1,2]
                },
                views: {
                    'content@': {
                        templateUrl: 'ForgotPassword/forgotpassword.html',
                        controller: 'ForgotPasswordController'
                    }
                }
            })
      
    
    
            //Send non-logged in users here
            .state('login', {
                url: '/login',
                views: {
                           'login': { 
                                templateUrl: 'Login/login.html',
                                controller: 'LoginController'
                            }
                       },
              resolve: {
                    authorize: ['identity',
                      function(identity) {
                        //$log.debug("Resolve authorization", authorization.authorize());
                        if(identity.isIdentityResolved()) {
                            return identity.logout();
                        }
                      }
                    ]
                }
            })
            

            //Unique case
            .state('mustChangePassword', {
                url: '/mustchangepassword',
                views: {
                    'login': {
                        templateUrl: 'MustChangePassword/mustchangepassword.html',
                        controller: 'MustChangePasswordController'
                    }
                },
                resolve: {
                    authorize: ['authorization', '$log',
                      function(authorization, $log) {
                        //$log.debug("Resolve authorization", authorization.authorize());
                        return authorization.authorize();
                      }
                    ]
                }
            })

    
            //This is the state for when someone is not authorized
            .state('accessdenied', {
                url: '/accessdenied',
                views: {
                           'content': { 
                                templateUrl: 'AccessDenied/accessdenied.html',
                                controller: 'AccessDeniedController'
                            },
                            'navigation': {
                                templateUrl: 'Navigation/navigation.html',
                                controller: 'NavigationController'
                            },
                            'header': {
                                templateUrl: 'Header/header.html',
                                controller: 'HeaderController'
                            }
                       }
            });
    
        $urlRouterProvider.otherwise("/");
}])
//The UI Router functions fire events depending on where in a state transition they are, we listen for them here.
//$stateChangeStart is where we check to make sure the user is authorized to see the next view
//$stateNotFound is for error handling
//$stateChangeError is for error handling
.run(['$rootScope', '$log', '$state', '$stateParams', 'authorization', 'identity', function ($rootScope, $log, $state, $stateParams, authorization, identity) {
 
    $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams, fromState, fromStateParams){
        $log.debug("$stateChangeStart: ", event, " from: ", fromState, " to: ", toState);
        //track the state the user wants to go to; authorization service nees this
        
        $rootScope.toState = toState;
        $rootScope.toStateParams = toStateParams;
        //if their identity is resolved, do an authorization check immediately
        if(identity.isIdentityResolved()){
            authorization.authorize().then(
                function(pl){$log.debug("$stateChangeStart Authorization Success")},
                function(err){$log.debug("Authorization Fail")}
            );
        } else {
            $log.debug("$stateChangeStart identity is not resolved");
        }
    })


    $rootScope.$on('$stateNotFound', function(event, unfoundState, fromState, fromParams){ 
        $log.debug("$stateNotFound: ", event, " to: ", unfoundState.to, " from: ", fromState); 
    })
    
    $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error){ 
        $log.debug("$stateChangeError to: ", toState, " from: ", fromState, " error: ", error);
    });
    
}])
//This is where you can turn off the verbose debugging that I've built into this project.
//It is also where the decorator that gives you line numbers for where $log outputs come from
.config(function logConfig($provide, $logProvider) {

    //Turn Debugging ON or OFF
    $logProvider.debugEnabled(true);
    
//    //We're need this here to add the line numbers that the error occurs to the $log output.
//    $provide.decorator('$log', function ($delegate) {
//        var originalFns = {};
//
//        // Store the original log functions
//        angular.forEach($delegate, function (originalFunction, functionName) {
//            originalFns[functionName] = originalFunction;
//        });
//
//        var functionsToDecorate = ['debug', 'warn'];
//
//        // Apply the decorations
//        angular.forEach(functionsToDecorate, function (functionName) {
//            $delegate[functionName] = logDecorator(originalFns[functionName]);
//        });
//
//        return $delegate;
//    });
//
//    function logDecorator(fn) {
//        return function () {
//
//            var args = [].slice.call(arguments);
//
//            // Insert a separator between the existing log message(s) and what we're adding.
//            args.push(' - ');
//
//            // Use (instance of Error)'s stack to get the current line.
//            var stack = (new Error()).stack.split('\n').slice(1);
//
//            // Throw away the first item because it is the `$log.fn()` function,
//            // but we want the code that called `$log.fn()`.
//            stack.shift();
//
//            // We only want the top line, thanks.
//            stack = stack.slice(1, 2);
//
//            // Put it on the args stack.
//            args.push(stack);
//
//            // Call the original function with the new args.
//            fn.apply(fn, args);
//        };
//    }
});
//
////Modules are initialized here so controllers can access them later
angular.module('App.MasterListController', []);
angular.module('App.UsersController', []);
