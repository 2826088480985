'use strict'

angular
.module('App.Services', [])
.service('$global', ['$rootScope', '$document', '$log', 'RESTService', '$alerts', function ($rootScope, $document, $log, RESTService, $alerts) {

    this.init = function(){
        $log.debug("Initizializing $global");
        this.alerts = [];
    };
    
    this.init();
    
    this.getProperty = function(propertyName, assignee){
                
        var parameters = [{'key':"tableName", 'value': propertyName }];
        
        return RESTService.get(RESTService.getControllerPath([this.RESTControllerNames.DOMAIN], parameters));        
    };    
    
    this.settings = {
            isSystemAdmin: false,
            isLoggedIn: false,
            fullName: '',
            alerts: [],
            notifications: [],
            messages: []
        };
    
    
    this.RESTControllerNames = {
        LOGIN: 'login',
        CHANGEPASS: 'changepassword',
        FORGOTPASS: 'forgotpassword',
        USER: 'asuser',
        SYSROLE: 'systemrole',
        USERNAMEUNIQUE: 'checkunique',
        NAVIGATION: 'navigation',
        COMMITTEE: 'committee',
        DOMAIN: 'masterlistdomain',
        PLEDGE: 'masterlistpledge',
        COMMITTEEMEMBER: 'masterlistcommittee',
        MASTERLIST: 'MasterList',
        DUES: 'MasterListDues',
        REPORTS: 'Report',
        LOCK: 'LockUser', 
        INVOICE: 'Invoice'
    };

//    this.sysRoleEnum = {
//        SYSTEM_ADMIN: 1
//    };

    this.get = function (key) {
        return this.settings[key];
    };

    this.set = function (key, value) {
        this.settings[key] = value;
    };

/*
    this.setSysRoleVars = function (sysRoleIdent) {
        switch (sysRoleIdent) {
            case this.sysRoleEnum.SYSTEM_ADMIN:
                $log.debug("User is Admin");
                this.set('isSystemAdmin', true);
                break;
            default:
                $log.debug("User is User");
                this.set('isSystemAdmin', false);
                break;
        }
    };
*/

    this.values = function () { return this.settings; };

}]) //end service
.service('RESTService', ['$http', '$location', '$log', function ($http, $location, $log) {

    //All of these should handle null or empty string values and log the errors
    //**Note that for every url that includes a '/' + ident request,
    //the name of the corresponding web api controller MUST be ident to match them

    this.protocol = 'http://'
    this.domain = 'localhost:50972';
    this.apiVersion = 'api';

    if ($location.$$port != 1337) {

                    this.protocol = $location.$$protocol + '://';
                    this.domain = $location.$$host;
                    this.apiVersion = 'api';
    }


    //Returns a concatenated path based on an array of variables.
    //eg. RESTService.getControllerPath([$global.RESTControllerNames.SWMASTER, $global.get('SWMasterID'),$global.RESTControllerNames.COMPANIES,$scope.company.SWCompanyID,$global.RESTControllerNames.ITEMCATEGORIES])
    //Returns a concatenated string: i.e. /swmaster/90/companies/1001/itemcategories
    this.getControllerPath = function(pathArray, parameters){
                
      var path = this.protocol + this.domain + "/" + this.apiVersion;

      if(angular.isArray(pathArray)){
        angular.forEach(pathArray, function(value, key){
          path += '/' + value;
        })
        
        if(parameters) {
            path +="?";
            var position = 0;
            angular.forEach(parameters, function(value, key){
            
                if(position === 0){
                    path += value.key + '=' + value.value;
                } else {
                    path += "&" + value.key + '=' + value.value;
                }
                
                position++;
            });
        
        }
        
      }else{
        $log.debug("getControllerName param is not an array.");
      };

      $log.log("getControllerName: " + path);
      
        return path;
    }




    //Posts a new JSON Object
    //Returns: Result Object
    this.post = function (controllerName, postData) {
        var request = $http({
            withCredentials: true,
            method: 'post',
            url: controllerName,
            data: postData
        });
        return request;
    }



    //Get an item or all items from an end-point
    //Returns: Result Object
    this.get = function (controllerName, ident) {
        var url = ""

        if(ident){
          url = controllerName + '/' + ident;
        } else {
          url = controllerName;
        }

        var request = $http({
            withCredentials: true,
            method: 'get',
            url: url
        });

        return request;
    }



    //Update an item
    //Returns: Result Object
    this.put = function (controllerName, ident, putData) {

      var url = controllerName

      //if there's 3 params, use ident and put the data, else the second parameter *IS* the payload
      if(putData){
        url = controllerName + '/' + ident;
      } else {
        putData = ident;
      }

        var request = $http({
	    withCredentials: true,
            method: 'put',
            url: url,
            data: putData
        });
        return request;
    }



    //Delete an item
    //Returns: Result Object
    this.delete = function (controllerName, deleteIdent) {

      var url = controllerName

      if(deleteIdent){
        url = controllerName + '/' + deleteIdent;
      } else {
        url = controllerName;
      }

        var request = $http({
	    withCredentials: true,
            method: 'delete',
            url: url
        });

        return request;
    }
    
}])
.service('$alerts', function() { //used to abstract our alerts functionality for use across pages
    
    this.alerts = [];
    
    this.addAlert = function(type, msg){
        this.alerts.push({ 'type' : type, 'msg': msg });
    };
    
    this.clearAlerts = function(){
        this.alerts = [];
    }
    
    this.closeAlert = function(index){
        this.alerts.splice(index, 1);
    }

})
.service('$states', function() { //used to keep our states array in one place
    
    return {
        states:[
            { name: 'NEW YORK', abbr: 'NY'},
            { name: 'ALABAMA', abbr: 'AL'},
            { name: 'ALASKA', abbr: 'AK'},
            { name: 'AMERICAN SAMOA', abbr: 'AS'},
            { name: 'ARIZONA', abbr: 'AZ'},
            { name: 'ARKANSAS', abbr: 'AR'},
            { name: 'CALIFORNIA', abbr: 'CA'},
            { name: 'COLORADO', abbr: 'CO'},
            { name: 'CONNECTICUT', abbr: 'CT'},
            { name: 'DELAWARE', abbr: 'DE'},
            { name: 'DISTRICT OF COLUMBIA', abbr: 'DC'},
            { name: 'FEDERATED STATES OF MICRONESIA', abbr: 'FM'},
            { name: 'FLORIDA', abbr: 'FL'},
            { name: 'GEORGIA', abbr: 'GA'},
            { name: 'GUAM', abbr: 'GU'},
            { name: 'HAWAII', abbr: 'HI'},
            { name: 'IDAHO', abbr: 'ID'},
            { name: 'ILLINOIS', abbr: 'IL'},
            { name: 'INDIANA', abbr: 'IN'},
            { name: 'IOWA', abbr: 'IA'},
            { name: 'KANSAS', abbr: 'KS'},
            { name: 'KENTUCKY', abbr: 'KY'},
            { name: 'LOUISIANA', abbr: 'LA'},
            { name: 'MAINE', abbr: 'ME'},
            { name: 'MARSHALL ISLANDS', abbr: 'MH'},
            { name: 'MARYLAND', abbr: 'MD'},
            { name: 'MASSACHUSETTS', abbr: 'MA'},
            { name: 'MICHIGAN', abbr: 'MI'},
            { name: 'MINNESOTA', abbr: 'MN'},
            { name: 'MISSISSIPPI', abbr: 'MS'},
            { name: 'MISSOURI', abbr: 'MO'},
            { name: 'MONTANA', abbr: 'MT'},
            { name: 'NEBRASKA', abbr: 'NE'},
            { name: 'NEVADA', abbr: 'NV'},
            { name: 'NEW HAMPSHIRE', abbr: 'NH'},
            { name: 'NEW JERSEY', abbr: 'NJ'},
            { name: 'NEW MEXICO', abbr: 'NM'},
            { name: 'NORTH CAROLINA', abbr: 'NC'},
            { name: 'NORTH DAKOTA', abbr: 'ND'},
            { name: 'NORTHERN MARIANA ISLANDS', abbr: 'MP'},
            { name: 'OHIO', abbr: 'OH'},
            { name: 'OKLAHOMA', abbr: 'OK'},
            { name: 'OREGON', abbr: 'OR'},
            { name: 'PALAU', abbr: 'PW'},
            { name: 'PENNSYLVANIA', abbr: 'PA'},
            { name: 'PUERTO RICO', abbr: 'PR'},
            { name: 'RHODE ISLAND', abbr: 'RI'},
            { name: 'SOUTH CAROLINA', abbr: 'SC'},
            { name: 'SOUTH DAKOTA', abbr: 'SD'},
            { name: 'TENNESSEE', abbr: 'TN'},
            { name: 'TEXAS', abbr: 'TX'},
            { name: 'UTAH', abbr: 'UT'},
            { name: 'VERMONT', abbr: 'VT'},
            { name: 'VIRGIN ISLANDS', abbr: 'VI'},
            { name: 'VIRGINIA', abbr: 'VA'},
            { name: 'WASHINGTON', abbr: 'WA'},
            { name: 'WEST VIRGINIA', abbr: 'WV'},
            { name: 'WISCONSIN', abbr: 'WI'},
            { name: 'WYOMING', abbr: 'WY' }
        ]
    };
})
.factory('templateCompiler', function ($templateCache, $compile) {
    return {
        getCompiledHTML: function ($scope, htmlTemplateName) {
            var tplCrop = $templateCache.get(htmlTemplateName);

            var template = angular.element(tplCrop);
            var linkFn = $compile(template);
            var compiledHTML = linkFn($scope);

            return compiledHTML;
        }
    }
})
.filter('safe_html', ['$sce', function ($sce) {
    return function (val) {
        return $sce.trustAsHtml(val);
    };
}]);
